import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  CircularProgress,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { authService } from '../../services/authService';

interface SeoData {
  id: number;
  seo_title: string;
  seo_description: string;
  type: 'homepage' | 'page' | 'treatment' | 'category' | 'clinic';
  structured_data: {
    '@context': string;
    '@type': string;
    name: string;
    description: string;
    url: string;
    mainEntity: {
      '@type': string;
      name: string;
      address: {
        '@type': string;
        addressCountry: string;
        addressLocality: string;
        addressRegion: string;
        postalCode: string;
        streetAddress: string;
      };
      contactPoint: {
        '@type': string;
        contactType: string;
        telephone: string;
        availableLanguage: string[];
      };
      logo: {
        '@type': string;
        url: string;
      };
      sameAs: string[];
    };
    faqPage: {
      '@type': string;
      mainEntity: Array<{
        '@type': string;
        name: string;
        acceptedAnswer: {
          '@type': string;
          text: string;
        };
      }>;
    };
  };
}

const API_URL = import.meta.env.VITE_API_URL;

const SeoSettings = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [seoData, setSeoData] = useState<SeoData | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const fetchSeoData = async (signal?: AbortSignal) => {
    try {
      const token = authService.getToken();
      if (!token) {
        setError('Token bulunamadı');
        return;
      }

      const response = await axios.get(`${API_URL}/api/seo/2`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        signal,
      });
      
      if (signal?.aborted) return;
      
      if (response.data.success) {
        setSeoData(response.data.data);
      } else {
        setError('Veri alınamadı');
      }
    } catch (err: any) {
      if (signal?.aborted) return;
      
      if (axios.isAxiosError(err)) {
        if (err.code === 'ERR_CANCELED') {
          return;
        }
        console.error('Veri getirme hatası:', err.response?.data);
        setError('Veri getirme sırasında bir hata oluştu');
      }
    } finally {
      if (!signal?.aborted) {
        setLoading(false);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    const abortController = new AbortController();
    try {
      const token = authService.getToken();
      if (!token) {
        setError('Token bulunamadı');
        return;
      }

      const payload = {
        seo_title: seoData?.seo_title,
        seo_description: seoData?.seo_description,
        type: seoData?.type || 'page',
        structured_data: {
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          name: seoData?.structured_data?.name,
          description: seoData?.structured_data?.description,
          url: seoData?.structured_data?.url,
          mainEntity: {
            '@type': 'Organization',
            name: seoData?.structured_data?.mainEntity?.name,
            address: {
              '@type': 'PostalAddress',
              addressCountry: seoData?.structured_data?.mainEntity?.address?.addressCountry,
              addressLocality: seoData?.structured_data?.mainEntity?.address?.addressLocality,
              addressRegion: seoData?.structured_data?.mainEntity?.address?.addressRegion,
              postalCode: seoData?.structured_data?.mainEntity?.address?.postalCode,
              streetAddress: seoData?.structured_data?.mainEntity?.address?.streetAddress
            },
            contactPoint: {
              '@type': 'ContactPoint',
              contactType: 'customer service',
              telephone: seoData?.structured_data?.mainEntity?.contactPoint?.telephone,
              availableLanguage: seoData?.structured_data?.mainEntity?.contactPoint?.availableLanguage || []
            },
            logo: {
              '@type': 'ImageObject',
              url: seoData?.structured_data?.mainEntity?.logo?.url
            },
            sameAs: seoData?.structured_data?.mainEntity?.sameAs || []
          },
          faqPage: {
            '@type': 'FAQPage',
            mainEntity: seoData?.structured_data?.faqPage?.mainEntity || []
          }
        }
      };

      const response = await axios.put(
        `${API_URL}/api/seo/${seoData?.id}`,
        payload,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          signal: abortController.signal,
        }
      );
      
      if (response.data.success) {
        setSuccessMessage('SEO ayarları başarıyla güncellendi');
        fetchSeoData(abortController.signal);
      } else {
        setError('Güncelleme başarısız: ' + response.data.message);
      }
    } catch (err: any) {
      if (!abortController.signal.aborted) {
        if (axios.isAxiosError(err)) {
          if (err.code === 'ERR_CANCELED') {
            return;
          }
          console.error('Hata detayı:', err.response?.data);
          setError(`Güncelleme sırasında hata oluştu: ${err.response?.data?.message || err.message}`);
        }
      }
    } finally {
      if (!abortController.signal.aborted) {
        setLoading(false);
      }
    }
    return () => {
      abortController.abort();
    };
  };

  const handleStructuredDataChange = (path: string, value: any) => {
    setSeoData(prev => {
      if (!prev) return null;
      const newData = { ...prev };
      const pathParts = path.split('.');
      let current: any = newData.structured_data;
      
      for (let i = 0; i < pathParts.length - 1; i++) {
        if (!current[pathParts[i]]) {
          current[pathParts[i]] = {};
        }
        current = current[pathParts[i]];
      }
      
      current[pathParts[pathParts.length - 1]] = value;
      return newData;
    });
  };

  const addFaqItem = () => {
    setSeoData(prev => {
      if (!prev) return null;
      const newData = { ...prev };
      if (!newData.structured_data.faqPage.mainEntity) {
        newData.structured_data.faqPage.mainEntity = [];
      }
      newData.structured_data.faqPage.mainEntity.push({
        '@type': 'Question',
        name: '',
        acceptedAnswer: {
          '@type': 'Answer',
          text: ''
        }
      });
      return newData;
    });
  };

  const removeFaqItem = (index: number) => {
    setSeoData(prev => {
      if (!prev) return null;
      const newData = { ...prev };
      newData.structured_data.faqPage.mainEntity.splice(index, 1);
      return newData;
    });
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchSeoData(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Paper sx={{ p: 3 }}>
        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">
            SEO Ayarları
          </Typography>
          {successMessage && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {successMessage}
            </Alert>
          )}
        </Box>
        
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="SEO Başlığı"
              value={seoData?.seo_title || ''}
              onChange={(e) => setSeoData(prev => prev ? {...prev, seo_title: e.target.value} : null)}
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="SEO Açıklaması"
              value={seoData?.seo_description || ''}
              onChange={(e) => setSeoData(prev => prev ? {...prev, seo_description: e.target.value} : null)}
            />
          </Grid>

          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Temel Bilgiler</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Sayfa Adı"
                      value={seoData?.structured_data?.name || ''}
                      onChange={(e) => handleStructuredDataChange('name', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Açıklama"
                      value={seoData?.structured_data?.description || ''}
                      onChange={(e) => handleStructuredDataChange('description', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="URL"
                      value={seoData?.structured_data?.url || ''}
                      onChange={(e) => handleStructuredDataChange('url', e.target.value)}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Kuruluş Bilgileri</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Kuruluş Adı"
                      value={seoData?.structured_data?.mainEntity?.name || ''}
                      onChange={(e) => handleStructuredDataChange('mainEntity.name', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Telefon"
                      value={seoData?.structured_data?.mainEntity?.contactPoint?.telephone || ''}
                      onChange={(e) => handleStructuredDataChange('mainEntity.contactPoint.telephone', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Logo URL"
                      value={seoData?.structured_data?.mainEntity?.logo?.url || ''}
                      onChange={(e) => handleStructuredDataChange('mainEntity.logo.url', e.target.value)}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Adres Bilgileri</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Sokak Adresi"
                      value={seoData?.structured_data?.mainEntity?.address?.streetAddress || ''}
                      onChange={(e) => handleStructuredDataChange('mainEntity.address.streetAddress', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Şehir"
                      value={seoData?.structured_data?.mainEntity?.address?.addressLocality || ''}
                      onChange={(e) => handleStructuredDataChange('mainEntity.address.addressLocality', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Bölge"
                      value={seoData?.structured_data?.mainEntity?.address?.addressRegion || ''}
                      onChange={(e) => handleStructuredDataChange('mainEntity.address.addressRegion', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Posta Kodu"
                      value={seoData?.structured_data?.mainEntity?.address?.postalCode || ''}
                      onChange={(e) => handleStructuredDataChange('mainEntity.address.postalCode', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Ülke"
                      value={seoData?.structured_data?.mainEntity?.address?.addressCountry || ''}
                      onChange={(e) => handleStructuredDataChange('mainEntity.address.addressCountry', e.target.value)}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ flex: 1 }}
                >
                  <Typography>SSS (Sıkça Sorulan Sorular)</Typography>
                </AccordionSummary>
                <Tooltip title="Yeni Soru Ekle">
                  <IconButton 
                    size="small" 
                    onClick={addFaqItem}
                    sx={{ mr: 2 }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {seoData?.structured_data?.faqPage?.mainEntity?.map((faq, index) => (
                    <Grid item xs={12} key={index}>
                      <Paper elevation={1} sx={{ p: 2, position: 'relative' }}>
                        <IconButton
                          size="small"
                          sx={{ position: 'absolute', right: 8, top: 8 }}
                          onClick={() => removeFaqItem(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Soru"
                              value={faq.name}
                              onChange={(e) => {
                                const newData = { ...seoData };
                                newData.structured_data.faqPage.mainEntity[index].name = e.target.value;
                                setSeoData(newData);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              multiline
                              rows={2}
                              label="Cevap"
                              value={faq.acceptedAnswer.text}
                              onChange={(e) => {
                                const newData = { ...seoData };
                                newData.structured_data.faqPage.mainEntity[index].acceptedAnswer.text = e.target.value;
                                setSeoData(newData);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Kaydet'}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default SeoSettings; 