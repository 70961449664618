import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import { imagesService, Image } from '../services/imagesService';

export const ImageDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [image, setImage] = useState<Image | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    const fetchImage = async () => {
      if (!id) return;

      try {
        setLoading(true);
        setError(null);
        const imageData = await imagesService.getImage(parseInt(id, 10));
        setImage(imageData);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to fetch image details';
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchImage();
  }, [id]);

  const handleDelete = async () => {
    if (!id) return;

    try {
      setLoading(true);
      await imagesService.deleteImage(parseInt(id, 10));
      navigate('/images');
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to delete image';
      setError(errorMessage);
    } finally {
      setLoading(false);
      setDeleteDialogOpen(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '60vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ mt: 3 }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/images')}
        >
          Back to Images
        </Button>
      </Container>
    );
  }

  if (!image) {
    return (
      <Container maxWidth="md" sx={{ mt: 3 }}>
        <Alert severity="warning" sx={{ mb: 2 }}>
          Image not found
        </Alert>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/images')}
        >
          Back to Images
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 3 }}>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/images')}
        >
          Back to Images
        </Button>
        <Box>
          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={() => navigate(`/images/${id}/edit`)}
            sx={{ mr: 1 }}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => setDeleteDialogOpen(true)}
          >
            Delete
          </Button>
        </Box>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 400,
              bgcolor: 'grey.100',
            }}
          >
            <img
              src={image.url}
              alt={`Image ${image.id}`}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Image Details
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary">
                ID
              </Typography>
              <Typography variant="body1" gutterBottom>
                {image.id}
              </Typography>

              <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                Clinic ID
              </Typography>
              <Typography variant="body1" gutterBottom>
                {image.clinic_id}
              </Typography>

              <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                View Type
              </Typography>
              <Typography variant="body1" gutterBottom>
                {image.view}
              </Typography>

              <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                Rank
              </Typography>
              <Typography variant="body1" gutterBottom>
                {image.rank}
              </Typography>

              <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                Created At
              </Typography>
              <Typography variant="body1" gutterBottom>
                {new Date(image.created_at).toLocaleString()}
              </Typography>

              <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                Updated At
              </Typography>
              <Typography variant="body1" gutterBottom>
                {new Date(image.updated_at).toLocaleString()}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete Image</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this image? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}; 