import axios from 'axios';
import { authService } from './authService';

export interface HeaderSettings {
  logo_path: string;
  add_clinic_text: string;
  add_clinic_url: string;
}

export interface HeaderSettingsResponse {
  success: boolean;
  data: HeaderSettings;
  message?: string;
}

const BASE_URL = import.meta.env.VITE_API_URL || 'http://localhost:3232';

// Create axios instance with base configuration
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
});

// Add request interceptor for authentication
api.interceptors.request.use(
  (config) => {
    const token = authService.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const headerSettingsService = {
  getHeaderSettings: async (signal?: AbortSignal): Promise<HeaderSettings> => {
    try {
      const response = await api.get<HeaderSettingsResponse>('/api/header-settings', { signal });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Header ayarları alınamadı');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.code === 'ERR_CANCELED') {
          throw new Error('Request was cancelled');
        }
        if (error.response?.status === 404) {
          throw error;
        }
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Header ayarları alınamadı'
        );
      }
      throw error;
    }
  },

  updateHeaderSettings: async (data: HeaderSettings, signal?: AbortSignal): Promise<HeaderSettings> => {
    try {
      const response = await api.put<HeaderSettingsResponse>('/api/header-settings', data, { signal });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Header ayarları güncellenemedi');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.code === 'ERR_CANCELED') {
          throw new Error('Request was cancelled');
        }
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Header ayarları güncellenemedi'
        );
      }
      throw error;
    }
  },

  uploadLogo: async (file: File, signal?: AbortSignal): Promise<string> => {
    try {
      const formData = new FormData();
      formData.append('logo', file);

      const response = await api.post<{ success: boolean; data: { path: string }; message?: string }>(
        '/api/header-settings/upload-logo',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          signal,
        }
      );

      if (!response.data.success) {
        throw new Error(response.data.message || 'Logo yüklenemedi');
      }

      return response.data.data.path;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.code === 'ERR_CANCELED') {
          throw new Error('Request was cancelled');
        }
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Logo yüklenemedi'
        );
      }
      throw error;
    }
  },
}; 