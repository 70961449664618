import axios from 'axios';

const OPENAI_API_URL = 'https://api.openai.com/v1/chat/completions';

const convertToEnglishChars = (text: string): string => {
  const charMap: { [key: string]: string } = {
    'ı': 'i', 'İ': 'I',
    'ğ': 'g', 'Ğ': 'G',
    'ü': 'u', 'Ü': 'U',
    'ş': 's', 'Ş': 'S',
    'ö': 'o', 'Ö': 'O',
    'ç': 'c', 'Ç': 'C',
    'â': 'a', 'Â': 'A',
    'î': 'i', 'Î': 'I',
    'û': 'u', 'Û': 'U'
  };
  
  return text.replace(/[ıİğĞüÜşŞöÖçÇâÂîÎûÛ]/g, match => charMap[match] || match);
};

export const openaiService = {
  improveAboutSection: async (title: string, currentAbout: string): Promise<string> => {
    try {
      const prompt = `As an expert medical content writer, create a unique and original about section for a hair transplant clinic named "${title}". This content will be displayed on a hair transplant platform's clinic listing detail page.
      
      Current about section for reference: "${currentAbout || 'No description available.'}"
      
      Requirements:
      1. Create a completely new and unique description
      2. Make it professional and engaging
      3. Maintain core services and specialties mentioned in the current text
      4. Structure it in a clear, well-organized way
      5. Focus on what makes this clinic special in hair transplantation field
      6. Use natural, flowing language
      7. Keep medical terminology accessible to potential hair transplant patients
      8. Highlight expertise and experience in hair transplantation
      9. Maximum 1200 characters (including spaces)
      10. Focus on building trust and credibility in hair restoration services
      11. Include relevant hair transplant specialties and techniques if mentioned
      12. IMPORTANT: Ensure all sentences are complete - do not cut off mid-sentence. If approaching the character limit, end the last sentence naturally, even if it means using fewer characters.
      
      Please provide a fresh, original description that captures the essence of the hair transplant clinic while being distinct from the current text. Ensure the content is optimized for potential hair transplant patients viewing the clinic's profile.`;

      const response = await axios.post(
        OPENAI_API_URL,
        {
          model: "gpt-3.5-turbo",
          messages: [{ role: "user", content: prompt }],
          temperature: 0.9,
          max_tokens: 500,
          presence_penalty: 0.6,
          frequency_penalty: 0.8
        },
        {
          headers: {
            'Authorization': `Bearer ${import.meta.env.VITE_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      let content = response.data.choices[0].message.content.trim();
      
      // If content exceeds 1200 characters, find the last complete sentence before the limit
      if (content.length > 1200) {
        const truncated = content.substring(0, 1200);
        const lastPeriodIndex = truncated.lastIndexOf('.');
        if (lastPeriodIndex > 0) {
          content = truncated.substring(0, lastPeriodIndex + 1);
        }
      }

      return content;
    } catch (error) {
      console.error('Error improving about section:', error);
      throw error;
    }
  },

  translateReview: async (review: string): Promise<string> => {
    try {
      const prompt = `Translate the following review to English. If it's already in English, return it as is. 
      Maintain the original tone and sentiment of the review.
      
      Review: "${review}"
      
      Requirements:
      1. Keep the translation natural and fluent
      2. Preserve any medical or technical terms related to hair transplantation
      3. Maintain the emotional context and personal experience
      4. Keep informal language style if present in the original
      5. Preserve any specific details about procedures or results`;

      const response = await axios.post(
        OPENAI_API_URL,
        {
          model: "gpt-3.5-turbo",
          messages: [{ role: "user", content: prompt }],
          temperature: 0.3, // Lower temperature for more accurate translations
          max_tokens: 500,
        },
        {
          headers: {
            'Authorization': `Bearer ${import.meta.env.VITE_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      return response.data.choices[0].message.content.trim();
    } catch (error) {
      console.error('Error translating review:', error);
      throw error;
    }
  },

  // Batch translation for multiple reviews
  translateReviews: async (reviews: Array<{ text: string; id: string }>): Promise<Array<{ id: string; originalText: string; translatedText: string }>> => {
    try {
      const translatedReviews = await Promise.all(
        reviews.map(async (review) => {
          try {
            const translatedText = await openaiService.translateReview(review.text);
            return {
              id: review.id,
              originalText: review.text,
              translatedText: translatedText
            };
          } catch (error) {
            console.error(`Error translating review ${review.id}:`, error);
            return {
              id: review.id,
              originalText: review.text,
              translatedText: review.text // Return original text if translation fails
            };
          }
        })
      );

      return translatedReviews;
    } catch (error) {
      console.error('Error in batch translation:', error);
      throw error;
    }
  },

  generateServiceDescription: async (serviceTitle: string): Promise<string> => {
    try {
      const prompt = `Generate a professional, technical medical description for a hair transplant clinic service titled "${serviceTitle}". The description should be concise (max 128 characters), focused on medical terminology and treatment specifics. This is for a hair transplant clinic's service listing.`;

      const response = await axios.post(
        OPENAI_API_URL,
        {
          model: "gpt-3.5-turbo",
          messages: [{ role: "user", content: prompt }],
          temperature: 0.7,
          max_tokens: 128
        },
        {
          headers: {
            'Authorization': `Bearer ${import.meta.env.VITE_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      return response.data.choices[0].message.content.trim().replace(/^"|"$/g, '');
    } catch (error) {
      console.error('Error generating service description:', error);
      throw error;
    }
  },

  improveStaffBiography: async (fullName: string, jobTitle: string, currentBio: string): Promise<string> => {
    try {
      const prompt = `As a professional medical content writer, improve the biography for a hair transplant clinic staff member.

Staff Member: "${fullName}"
Job Title: "${jobTitle}"
Current Biography: "${currentBio || 'No biography available.'}"

Requirements:
1. Create a professional and engaging biography
2. Highlight expertise and experience in the medical/hair transplant field
3. Use natural, flowing language that builds trust
4. Keep medical terminology accessible to potential patients
5. Maximum 1200 characters (including spaces)
6. Focus on their role and contributions to hair transplantation
7. Maintain a professional yet approachable tone
8. IMPORTANT: Ensure all sentences are complete - do not cut off mid-sentence
9. If approaching character limit, end the last sentence naturally
10. CRITICAL: Use only English characters (a-z, A-Z, 0-9) and basic punctuation (.,!?@#$%^&*()_+-=[]{};\\':"\\|,.<>/?~\`)
11. DO NOT use any Turkish characters (ı, İ, ğ, Ğ, ü, Ü, ş, Ş, ö, Ö, ç, Ç) - convert them to their English equivalents

Please provide an improved, professional biography that captures the staff member's expertise while being engaging for potential patients.`;

      const response = await axios.post(
        OPENAI_API_URL,
        {
          model: "gpt-3.5-turbo",
          messages: [{ role: "user", content: prompt }],
          temperature: 0.7,
          max_tokens: 800,
          presence_penalty: 0.6,
          frequency_penalty: 0.8
        },
        {
          headers: {
            'Authorization': `Bearer ${import.meta.env.VITE_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      let content = response.data.choices[0].message.content.trim();
      
      // Convert any remaining Turkish characters to English
      content = convertToEnglishChars(content);
      
      // If content exceeds 1200 characters, find the last complete sentence before the limit
      if (content.length > 1200) {
        const truncated = content.substring(0, 1200);
        const lastPeriodIndex = truncated.lastIndexOf('.');
        if (lastPeriodIndex > 0) {
          content = truncated.substring(0, lastPeriodIndex + 1);
        }
      }

      return content;
    } catch (error) {
      console.error('Error improving staff biography:', error);
      throw error;
    }
  },

  suggestServiceFeatures: async (serviceTitle: string, description: string): Promise<string[]> => {
    try {
      const prompt = `Analyze the following hair transplant service description and extract key features:

Service Title: "${serviceTitle}"
Current Description: "${description}"

Task: Extract Key Features
Please carefully analyze the provided description and extract 4-6 key features that are explicitly mentioned or strongly implied in the text.

Requirements:
1. Only extract features that are actually present in or strongly implied by the current description
2. Each feature should be concise (2-5 words)
3. Focus on medical benefits, techniques, and advantages mentioned
4. Use professional medical terminology where appropriate
5. Format as a simple comma-separated list
6. Do not invent or suggest new features - only extract what's in the text

Example format:
Advanced FUE Technology, Natural Hairline Design, Minimal Scarring, Long-lasting Results

Please provide only the extracted features in a comma-separated list, without any additional text or labels.`;

      const response = await axios.post(
        OPENAI_API_URL,
        {
          model: "gpt-3.5-turbo",
          messages: [{ role: "user", content: prompt }],
          temperature: 0.3, // Lower temperature for more accurate extraction
          max_tokens: 200
        },
        {
          headers: {
            'Authorization': `Bearer ${import.meta.env.VITE_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const content = response.data.choices[0].message.content.trim();
      
      // Split the comma-separated list into an array and clean up each feature
      return content.split(',').map((feature: string) => feature.trim()).filter(Boolean);
    } catch (error) {
      console.error('Error suggesting service features:', error);
      throw error;
    }
  },

  generateTreatmentContent: async (prompt: string): Promise<string> => {
    try {
      const response = await axios.post(
        OPENAI_API_URL,
        {
          model: "gpt-3.5-turbo",
          messages: [{ role: "user", content: prompt }],
          temperature: 0.7,
          max_tokens: 2000,
          presence_penalty: 0.0,
          frequency_penalty: 0.0
        },
        {
          headers: {
            'Authorization': `Bearer ${import.meta.env.VITE_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const content = response.data.choices[0].message.content.trim();
      
      // Validate that the response is complete JSON
      try {
        JSON.parse(content);
        return content;
      } catch (parseError) {
        console.error('Incomplete or invalid JSON from OpenAI:', content);
        throw new Error('Generated content was incomplete or invalid. Please try again.');
      }
    } catch (error) {
      console.error('Error generating treatment content:', error);
      throw error;
    }
  }
}; 