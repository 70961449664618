import { RouteObject } from 'react-router-dom';
import Layout from '../components/Layout';
import ProtectedRoute from '../components/ProtectedRoute';
import { lazy, Suspense } from 'react';
import { CategoriesPage } from '../pages/CategoriesPage';
import { TreatmentsPage } from '../pages/TreatmentsPage';
import { TreatmentDetailPage } from '../pages/TreatmentDetailPage';
import { ClinicLoginsPage } from '../pages/ClinicLoginsPage';
import { SettingsPage } from '../pages/SettingsPage';
import { ServicesPage } from '../pages/ServicesPage';
import { ImagesPage } from '../pages/ImagesPage';
import { ImageDetailPage } from '../pages/ImageDetailPage';
import { PagesListPage } from '../pages/PagesListPage';
import { PageDetailPage } from '../pages/PageDetailPage';

// Lazy load pages
const Login = lazy(() => import('../pages/Login'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const AdminList = lazy(() => import('../pages/AdminListPage'));
const Clinics = lazy(() => import('../pages/ClinicsPage'));
const ClinicRequests = lazy(() => import('../pages/ClinicRequestsPage'));
const Leads = lazy(() => import('../pages/LeadsPage'));
const Offers = lazy(() => import('../pages/OffersPage'));

const LoadingFallback = () => <div>Loading...</div>;

export const routes: RouteObject[] = [
  {
    path: '/login',
    element: <Suspense fallback={<LoadingFallback />}><Login /></Suspense>,
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <Suspense fallback={<LoadingFallback />}><Dashboard /></Suspense>,
      },
      {
        path: 'admins',
        element: <Suspense fallback={<LoadingFallback />}><AdminList /></Suspense>,
      },
      {
        path: 'clinics',
        element: <Suspense fallback={<LoadingFallback />}><Clinics /></Suspense>,
      },
      {
        path: 'categories',
        element: <Suspense fallback={<LoadingFallback />}><CategoriesPage /></Suspense>,
      },
      {
        path: 'treatments',
        element: <Suspense fallback={<LoadingFallback />}><TreatmentsPage /></Suspense>,
      },
      {
        path: 'treatments/:id',
        element: <Suspense fallback={<LoadingFallback />}><TreatmentDetailPage /></Suspense>,
      },
      {
        path: 'clinic-logins',
        element: <Suspense fallback={<LoadingFallback />}><ClinicLoginsPage /></Suspense>,
      },
      {
        path: 'clinic-requests',
        element: <Suspense fallback={<LoadingFallback />}><ClinicRequests /></Suspense>,
      },
      {
        path: 'leads',
        element: <Suspense fallback={<LoadingFallback />}><Leads /></Suspense>,
      },
      {
        path: '/settings/*',
        element: <SettingsPage />,
      },
      {
        path: '/offers',
        element: <Suspense fallback={<LoadingFallback />}><Offers /></Suspense>,
      },
      {
        path: 'services',
        element: <Suspense fallback={<LoadingFallback />}><ServicesPage /></Suspense>,
      },
      {
        path: 'images',
        element: <Suspense fallback={<LoadingFallback />}><ImagesPage /></Suspense>,
      },
      {
        path: 'images/:id',
        element: <Suspense fallback={<LoadingFallback />}><ImageDetailPage /></Suspense>,
      },
      {
        path: 'pages',
        element: <Suspense fallback={<LoadingFallback />}><PagesListPage /></Suspense>,
      },
      {
        path: 'pages/:id',
        element: <Suspense fallback={<LoadingFallback />}><PageDetailPage /></Suspense>,
      },
    ],
  },
  {
    path: '/categories',
    element: <CategoriesPage />,
  },
  {
    path: '*',
    element: <div>404 - Page not found</div>,
  },
]; 