import axios from 'axios';
import { authService } from './authService';

export interface Page {
  id: number;
  title: string;
  slug: string;
  content: string;
  status: 'ACTIVE' | 'INACTIVE';
  seo_title: string;
  seo_description: string;
  created_at: string;
  updated_at: string;
}

export interface PageCreateInput {
  title: string;
  slug: string;
  content: string;
  status: 'ACTIVE' | 'INACTIVE';
  seo_title: string;
  seo_description: string;
}

export interface PageUpdateInput {
  title?: string;
  slug?: string;
  content?: string;
  status?: 'ACTIVE' | 'INACTIVE';
  seo_title?: string;
  seo_description?: string;
}

export interface PageListResponse {
  success: boolean;
  data: Page[];
  meta: {
    current_page: number;
    per_page: number;
    total: number;
    total_pages: number;
  };
  message?: string;
}

export interface PageResponse {
  success: boolean;
  data: Page;
  message?: string;
}

export interface PageListParams {
  page?: number;
  limit?: number;
  search?: string;
  status?: 'ACTIVE' | 'INACTIVE';
}

const BASE_URL = import.meta.env.VITE_API_URL;

// Create axios instance with base configuration
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
});

// Add request interceptor for authentication
api.interceptors.request.use(
  (config) => {
    const token = authService.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const pagesService = {
  getPagesList: async ({ page = 1, limit = 10, search = '', status }: PageListParams = {}, signal?: AbortSignal) => {
    try {
      const response = await api.get<PageListResponse>('/api/pages', {
        params: {
          page,
          per_page: limit,
          search,
          status,
        },
        signal,
      });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to fetch pages list');
      }

      return {
        pages: response.data.data,
        total: response.data.meta.total || 0,
        currentPage: response.data.meta.current_page,
        totalPages: response.data.meta.total_pages,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.code === 'ERR_CANCELED') {
          throw new Error('Request was cancelled');
        }
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to fetch pages list'
        );
      }
      throw error;
    }
  },

  getPage: async (id: number, signal?: AbortSignal): Promise<Page> => {
    try {
      const response = await api.get<PageResponse>(`/api/pages/${id}`, { signal });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to fetch page');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.code === 'ERR_CANCELED') {
          throw new Error('Request was cancelled');
        }
        if (error.response?.status === 404) {
          throw new Error('Page not found');
        }
        if (error.response?.status === 401) {
          authService.logout();
          throw new Error('Session expired. Please login again.');
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to fetch page'
        );
      }
      throw error;
    }
  },

  createPage: async (data: PageCreateInput, signal?: AbortSignal): Promise<Page> => {
    try {
      const response = await api.post<PageResponse>('/api/pages', data, { signal });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to create page');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.code === 'ERR_CANCELED') {
          throw new Error('Request was cancelled');
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to create page'
        );
      }
      throw error;
    }
  },

  updatePage: async (id: number, data: PageUpdateInput, signal?: AbortSignal): Promise<Page> => {
    try {
      const response = await api.put<PageResponse>(`/api/pages/${id}`, data, { signal });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to update page');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.code === 'ERR_CANCELED') {
          throw new Error('Request was cancelled');
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to update page'
        );
      }
      throw error;
    }
  },

  deletePage: async (id: number, signal?: AbortSignal): Promise<void> => {
    try {
      const response = await api.delete<PageResponse>(`/api/pages/${id}`, { signal });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to delete page');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.code === 'ERR_CANCELED') {
          throw new Error('Request was cancelled');
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to delete page'
        );
      }
      throw error;
    }
  },
}; 