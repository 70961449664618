import axios from 'axios';
import { authService } from './authService';

export interface KanbanStage {
  id: string;
  name: string;
  description: string;
  order_position: number;
  settings: {
    color: string;
    icon: string;
  };
  status: 'active' | 'inactive';
  created_at?: string;
  updated_at?: string;
}

export interface KanbanStageCreateInput {
  name: string;
  description: string;
  order_position: number;
  settings: {
    color: string;
    icon: string;
  };
  status: 'active' | 'inactive';
}

export interface KanbanStageUpdateInput {
  name?: string;
  description?: string;
  order_position?: number;
  settings?: {
    color: string;
    icon: string;
  };
  status?: 'active' | 'inactive';
}

export interface KanbanStageListResponse {
  success: boolean;
  data: KanbanStage[];
  message?: string;
}

export interface KanbanStageResponse {
  success: boolean;
  data: KanbanStage;
  message?: string;
}

const BASE_URL = import.meta.env.VITE_API_URL || 'http://localhost:3232';

// Create axios instance with base configuration
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
});

// Add request interceptor for authentication
api.interceptors.request.use(
  (config) => {
    const token = authService.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const kanbanStagesService = {
  getKanbanStages: async () => {
    try {
      const response = await api.get<KanbanStageListResponse>('/api/kanban-stages');

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to fetch kanban stages');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to fetch kanban stages'
        );
      }
      throw error;
    }
  },

  createKanbanStage: async (data: KanbanStageCreateInput): Promise<KanbanStage> => {
    try {
      const response = await api.post<KanbanStageResponse>('/api/kanban-stages', data);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to create kanban stage');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to create kanban stage'
        );
      }
      throw error;
    }
  },

  updateKanbanStage: async (id: string, data: KanbanStageUpdateInput): Promise<KanbanStage> => {
    try {
      const response = await api.put<KanbanStageResponse>(`/api/kanban-stages/${id}`, data);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to update kanban stage');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to update kanban stage'
        );
      }
      throw error;
    }
  },

  deleteKanbanStage: async (id: string): Promise<void> => {
    try {
      const response = await api.delete<KanbanStageResponse>(`/api/kanban-stages/${id}`);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to delete kanban stage');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to delete kanban stage'
        );
      }
      throw error;
    }
  },

  updateKanbanStagePosition: async (id: string, order_position: number): Promise<KanbanStage> => {
    try {
      const response = await api.put<KanbanStageResponse>(`/api/kanban-stages/${id}`, {
        order_position,
      });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to update kanban stage position');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to update kanban stage position'
        );
      }
      throw error;
    }
  },
}; 