import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Paper,
  TextField,
  Button,
  Alert,
  Typography,
  Grid,
  CircularProgress,
  IconButton,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  Upload as UploadIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  ExpandMore as ExpandMoreIcon,
  DragIndicator as DragIcon,
  Edit as EditIcon,
  ArrowBack,
} from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { footerSettingsService, FooterSettings } from '../../services/footerSettingsService';

const initialSettings: FooterSettings = {
  id: undefined,
  name: '',
  logo_path: '',
  menus: [
    {
      title: 'Yeni Menü',
      items: [{ label: 'Yeni Bağlantı', url: '/new-link' }],
    },
  ],
};

export const FooterSettingsPage: React.FC = () => {
  const [footerList, setFooterList] = useState<FooterSettings[]>([]);
  const [selectedFooter, setSelectedFooter] = useState<FooterSettings>(initialSettings);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [logoPreview, setLogoPreview] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{ menuIndex: number; itemIndex: number } | null>(null);
  const [footerDeleteDialogOpen, setFooterDeleteDialogOpen] = useState(false);
  const [footerToDelete, setFooterToDelete] = useState<number | null>(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    fetchFooters();
  }, []);

  useEffect(() => {
    if (selectedFooter?.logo_path) {
      setLogoPreview(selectedFooter.logo_path);
    } else {
      setLogoPreview(null);
    }
  }, [selectedFooter?.logo_path]);

  const fetchFooters = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await footerSettingsService.getAllFooterSettings();
      console.log('API Response:', response);

      if (response?.settings && Array.isArray(response.settings)) {
        setFooterList(response.settings);
      } else {
        setFooterList([]);
        console.error('Invalid API response format:', response);
      }
    } catch (err: any) {
      console.error('Error fetching footer settings:', err);
      setError(err instanceof Error ? err.message : 'Footer ayarları yüklenemedi');
      setFooterList([]);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateNew = () => {
    setSelectedFooter({ ...initialSettings });
    setLogoPreview(null);
    setShowForm(true);
  };

  const handleEdit = (footer: FooterSettings) => {
    setSelectedFooter({ ...footer });
    setLogoPreview(footer.logo_path || null);
    setShowForm(true);
  };

  const handleDelete = (id: number) => {
    setFooterToDelete(id);
    setFooterDeleteDialogOpen(true);
  };

  const confirmFooterDelete = async () => {
    if (!footerToDelete) return;

    try {
      setLoading(true);
      await footerSettingsService.deleteFooterSettings(footerToDelete);
      await fetchFooters();
      setSuccess('Footer başarıyla silindi');
      if (selectedFooter?.id === footerToDelete) {
        setSelectedFooter(initialSettings);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Footer silinemedi');
    } finally {
      setLoading(false);
      setFooterDeleteDialogOpen(false);
      setFooterToDelete(null);
    }
  };

  const handleCancel = () => {
    setSelectedFooter(initialSettings);
    setShowForm(false);
    setError(null);
    setSuccess(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setLoading(true);
      setError(null);
      setSuccess(null);

      let updatedFooter: FooterSettings;

      if (selectedFooter.id) {
        updatedFooter = await footerSettingsService.updateFooterSettings(selectedFooter.id, selectedFooter);
      } else {
        updatedFooter = await footerSettingsService.createFooterSettings(selectedFooter);
      }

      setSelectedFooter(updatedFooter);
      await fetchFooters();
      setSuccess('Footer ayarları başarıyla kaydedildi');
      setShowForm(false);
    } catch (err) {
      console.error('Error saving footer settings:', err);
      setError(err instanceof Error ? err.message : 'Footer ayarları kaydedilemedi');
    } finally {
      setLoading(false);
    }
  };

  const handleLogoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !selectedFooter) return;

    try {
      setLoading(true);
      setError(null);
      
      // Create a preview URL
      const previewUrl = URL.createObjectURL(file);
      setLogoPreview(previewUrl);

      // Upload the file
      const logoPath = await footerSettingsService.uploadLogo(file);
      
      // Update settings with the new logo path
      setSelectedFooter({
        ...selectedFooter,
        logo_path: logoPath,
      });

      setSuccess('Logo başarıyla yüklendi');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Logo yüklenemedi');
      // Revert to the previous logo if upload fails
      setLogoPreview(selectedFooter.logo_path);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveLogo = () => {
    setSelectedFooter({
      ...selectedFooter,
      logo_path: '',
    });
    setLogoPreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleMenuTitleChange = (index: number, title: string) => {
    if (!selectedFooter) return;
    const newMenus = [...selectedFooter.menus];
    newMenus[index] = { ...newMenus[index], title };
    setSelectedFooter({ ...selectedFooter, menus: newMenus });
  };

  const handleMenuItemChange = (menuIndex: number, itemIndex: number, field: 'label' | 'url', value: string) => {
    if (!selectedFooter) return;
    const newMenus = [...selectedFooter.menus];
    newMenus[menuIndex].items[itemIndex] = {
      ...newMenus[menuIndex].items[itemIndex],
      [field]: value,
    };
    setSelectedFooter({ ...selectedFooter, menus: newMenus });
  };

  const handleAddMenu = () => {
    if (!selectedFooter) return;
    setSelectedFooter({
      ...selectedFooter,
      menus: [
        ...selectedFooter.menus,
        {
          title: 'Yeni Menü',
          items: [{ label: 'Yeni Bağlantı', url: '/new-link' }],
        },
      ],
    });
  };

  const handleAddMenuItem = (menuIndex: number) => {
    if (!selectedFooter) return;
    const newMenus = [...selectedFooter.menus];
    newMenus[menuIndex].items.push({ label: 'Yeni Bağlantı', url: '/new-link' });
    setSelectedFooter({ ...selectedFooter, menus: newMenus });
  };

  const handleDeleteMenuItem = (menuIndex: number, itemIndex: number) => {
    setItemToDelete({ menuIndex, itemIndex });
    setDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    if (!itemToDelete || !selectedFooter) return;
    
    const newMenus = [...selectedFooter.menus];
    newMenus[itemToDelete.menuIndex].items.splice(itemToDelete.itemIndex, 1);
    
    // Remove menu if it has no items
    if (newMenus[itemToDelete.menuIndex].items.length === 0) {
      newMenus.splice(itemToDelete.menuIndex, 1);
    }
    
    setSelectedFooter({ ...selectedFooter, menus: newMenus });
    setDeleteDialogOpen(false);
    setItemToDelete(null);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !selectedFooter) return;

    const { source, destination } = result;
    const newMenus = [...selectedFooter.menus];

    // If moving menu
    if (result.type === 'menu') {
      const [removed] = newMenus.splice(source.index, 1);
      newMenus.splice(destination.index, 0, removed);
    }
    // If moving menu item
    else {
      const sourceMenu = parseInt(source.droppableId);
      const destMenu = parseInt(destination.droppableId);
      const [removed] = newMenus[sourceMenu].items.splice(source.index, 1);
      newMenus[destMenu].items.splice(destination.index, 0, removed);
    }

    setSelectedFooter({ ...selectedFooter, menus: newMenus });
  };

  if (loading && footerList.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Footer Ayarları
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}

      {/* Footer Listesi */}
      {!showForm && (
        <Paper sx={{ mb: 3 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Footer Adı</TableCell>
                  <TableCell>Menü Sayısı</TableCell>
                  <TableCell>Oluşturulma Tarihi</TableCell>
                  <TableCell align="right">
                    <Button
                      startIcon={<AddIcon />}
                      variant="contained"
                      size="small"
                      onClick={handleCreateNew}
                    >
                      Yeni Footer
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {footerList.map((footer) => (
                  <TableRow key={footer.id}>
                    <TableCell>{footer.name}</TableCell>
                    <TableCell>{footer.menus.length}</TableCell>
                    <TableCell>
                      {new Date(footer.created_at || '').toLocaleDateString('tr-TR')}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        color="primary"
                        onClick={() => handleEdit(footer)}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleDelete(footer.id!)}
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}

      {/* Footer Düzenleme Formu */}
      {showForm && (
        <Box>
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" color="primary">
              {selectedFooter.id ? 'Footer Düzenle' : 'Yeni Footer Oluştur'}
            </Typography>
            <Button
              variant="outlined"
              onClick={handleCancel}
              startIcon={<ArrowBack />}
            >
              Listeye Dön
            </Button>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Paper sx={{ p: 3 }} id="footer-form">
                <Box sx={{ mb: 2 }}>
                  <Typography variant="h6" color="primary">
                    {selectedFooter.id ? 'Footer Düzenle' : 'Yeni Footer Oluştur'}
                  </Typography>
                </Box>
                <form onSubmit={handleSubmit}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <TextField
                      label="Footer Adı"
                      value={selectedFooter.name || ''}
                      onChange={(e) => setSelectedFooter({ ...selectedFooter, name: e.target.value })}
                      fullWidth
                      required
                      autoFocus={!selectedFooter.id} // Yeni oluşturmada otomatik fokus
                    />

                    <Box>
                      <Typography variant="subtitle1" color="primary" gutterBottom>
                        Logo Ayarları
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Button
                          variant="outlined"
                          component="label"
                          startIcon={<UploadIcon />}
                          disabled={loading}
                        >
                          Logo Yükle
                          <input
                            ref={fileInputRef}
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={handleLogoUpload}
                          />
                        </Button>
                        {selectedFooter.logo_path && (
                          <IconButton
                            color="error"
                            onClick={handleRemoveLogo}
                            disabled={loading}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Box>

                    <Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="subtitle1" color="primary">
                          Menüler
                        </Typography>
                        <Button
                          startIcon={<AddIcon />}
                          onClick={handleAddMenu}
                          variant="outlined"
                          size="small"
                        >
                          Yeni Menü Ekle
                        </Button>
                      </Box>

                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="menus" type="menu">
                          {(provided) => (
                            <Box
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                            >
                              {selectedFooter.menus.map((menu, menuIndex) => (
                                <Draggable
                                  key={`menu-${menuIndex}`}
                                  draggableId={`menu-${menuIndex}`}
                                  index={menuIndex}
                                >
                                  {(provided, snapshot) => (
                                    <Paper
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      elevation={snapshot.isDragging ? 3 : 1}
                                      sx={{
                                        p: 2,
                                        bgcolor: snapshot.isDragging ? 'action.hover' : 'background.paper',
                                      }}
                                    >
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          {...provided.dragHandleProps}
                                        >
                                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <DragIcon color="action" />
                                            <TextField
                                              size="small"
                                              value={menu.title || ''}
                                              onChange={(e) => handleMenuTitleChange(menuIndex, e.target.value)}
                                              onClick={(e) => e.stopPropagation()}
                                              onFocus={(e) => e.stopPropagation()}
                                            />
                                          </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Droppable droppableId={`${menuIndex}`} type="menuItem">
                                            {(provided) => (
                                              <Box
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
                                              >
                                                {menu.items.map((item, itemIndex) => (
                                                  <Draggable
                                                    key={`item-${menuIndex}-${itemIndex}`}
                                                    draggableId={`item-${menuIndex}-${itemIndex}`}
                                                    index={itemIndex}
                                                  >
                                                    {(provided, snapshot) => (
                                                      <Box
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        sx={{
                                                          display: 'flex',
                                                          gap: 2,
                                                          alignItems: 'center',
                                                          p: 1,
                                                          bgcolor: snapshot.isDragging ? 'action.hover' : 'transparent',
                                                          borderRadius: 1,
                                                        }}
                                                      >
                                                        <DragIcon color="action" />
                                                        <TextField
                                                          size="small"
                                                          label="Bağlantı Metni"
                                                          value={item.label || ''}
                                                          onChange={(e) => handleMenuItemChange(menuIndex, itemIndex, 'label', e.target.value)}
                                                          sx={{ flex: 1 }}
                                                        />
                                                        <TextField
                                                          size="small"
                                                          label="URL"
                                                          value={item.url || ''}
                                                          onChange={(e) => handleMenuItemChange(menuIndex, itemIndex, 'url', e.target.value)}
                                                          sx={{ flex: 1 }}
                                                        />
                                                        <IconButton
                                                          size="small"
                                                          color="error"
                                                          onClick={() => handleDeleteMenuItem(menuIndex, itemIndex)}
                                                        >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                      </Box>
                                                    )}
                                                  </Draggable>
                                                ))}
                                                {provided.placeholder}
                                                <Button
                                                  startIcon={<AddIcon />}
                                                  onClick={() => handleAddMenuItem(menuIndex)}
                                                  size="small"
                                                >
                                                  Bağlantı Ekle
                                                </Button>
                                              </Box>
                                            )}
                                          </Droppable>
                                        </AccordionDetails>
                                      </Accordion>
                                    </Paper>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </Box>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 2 }}>
                      <Button
                        variant="outlined"
                        onClick={handleCancel}
                        disabled={loading}
                      >
                        Vazgeç
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={24} /> : 'Değişiklikleri Kaydet'}
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Paper>
            </Grid>

            {/* Preview */}
            <Grid item xs={12} md={4}>
              <Paper sx={{ p: 3, position: 'sticky', top: 20 }}>
                <Typography variant="subtitle1" gutterBottom color="primary">
                  Önizleme
                </Typography>
                <Card variant="outlined">
                  <CardContent>
                    {logoPreview ? (
                      <Box sx={{ mb: 2, maxWidth: '200px' }}>
                        <img
                          src={logoPreview}
                          alt="Logo Preview"
                          style={{ width: '100%', height: 'auto' }}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          mb: 2,
                          width: '200px',
                          height: '60px',
                          bgcolor: 'grey.100',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: 1,
                        }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          Logo yüklenmedi
                        </Typography>
                      </Box>
                    )}

                    <Grid container spacing={2}>
                      {selectedFooter.menus.map((menu, index) => (
                        <Grid item xs={12} key={index}>
                          <Typography variant="subtitle2" gutterBottom>
                            {menu.title}
                          </Typography>
                          <List dense>
                            {menu.items.map((item, itemIndex) => (
                              <ListItem key={itemIndex} sx={{ px: 0 }}>
                                <ListItemText
                                  primary={item.label}
                                  secondary={item.url}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}

      {/* Delete Confirmation Dialogs */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>
          Bağlantıyı Sil
        </DialogTitle>
        <DialogContent>
          Bu bağlantıyı silmek istediğinizden emin misiniz?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>İptal</Button>
          <Button onClick={confirmDelete} color="error" variant="contained">
            Sil
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={footerDeleteDialogOpen}
        onClose={() => setFooterDeleteDialogOpen(false)}
      >
        <DialogTitle>
          Footer'ı Sil
        </DialogTitle>
        <DialogContent>
          Bu footer'ı silmek istediğinizden emin misiniz?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFooterDeleteDialogOpen(false)}>İptal</Button>
          <Button onClick={confirmFooterDelete} color="error" variant="contained">
            Sil
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}; 