import axios from 'axios';
import { authService } from './authService';

export interface Service {
  id: number;
  clinic_id: number;
  title: string;
  content: string;
  price_min: number;
  price_max: number;
  rank: number;
  status: 'ACTIVE' | 'INACTIVE';
  duration: string;
  recovery: string;
  key_features: string;
  created_at: string;
  updated_at: string;
  is_deleted: number;
}

export interface ServiceCreateInput {
  clinic_id: number;
  title: string;
  content: string;
  price_min: number;
  price_max: number;
  rank: number;
  status: 'ACTIVE' | 'INACTIVE';
  duration: string;
  recovery: string;
  key_features: string;
}

export interface ServiceUpdateInput {
  clinic_id?: number;
  title?: string;
  content?: string;
  price_min?: number;
  price_max?: number;
  rank?: number;
  status?: 'ACTIVE' | 'INACTIVE';
  duration?: string;
  recovery?: string;
  key_features?: string;
}

export interface ServiceListResponse {
  data: {
    data: Service[];
    meta: {
      current_page: number;
      per_page: number;
      total: number;
      total_pages: number;
    };
    success: boolean;
  };
  success: boolean;
}

export interface ServiceResponse {
  data: {
    data: Service;
    success: boolean;
  };
  success: boolean;
}

export interface ServiceListParams {
  page?: number;
  limit?: number;
  search?: string;
  clinic_id?: number;
}

const BASE_URL = import.meta.env.VITE_API_URL || 'http://localhost:3232';

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
});

api.interceptors.request.use(
  (config) => {
    const token = authService.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const servicesService = {
  getServices: async ({ page = 1, limit = 10, search = '', clinic_id }: ServiceListParams = {}, signal?: AbortSignal) => {
    try {
      const response = await api.get<ServiceListResponse>('/api/services', {
        params: {
          page,
          per_page: limit,
          search,
          clinic_id,
        },
        signal,
      });

      if (!response.data.success || !response.data.data.success) {
        throw new Error('Failed to fetch services');
      }

      return {
        services: response.data.data.data,
        total: response.data.data.meta.total || 0
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.name === 'AbortError' || error.code === 'ERR_CANCELED') {
          throw new Error('Request was cancelled');
        }
        
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to fetch services list'
        );
      }
      throw error;
    }
  },

  getService: async (id: number): Promise<Service> => {
    try {
      const response = await api.get<ServiceResponse>(`/api/services/${id}`);

      if (!response.data.success || !response.data.data.success) {
        throw new Error('Failed to fetch service');
      }

      return response.data.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          throw new Error('Service not found');
        }
        if (error.response?.status === 401) {
          authService.logout();
          throw new Error('Session expired. Please login again.');
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to fetch service'
        );
      }
      throw error;
    }
  },

  createService: async (data: ServiceCreateInput): Promise<Service> => {
    try {
      const response = await api.post<ServiceResponse>('/api/services', data);

      if (!response.data.success || !response.data.data.success) {
        throw new Error('Failed to create service');
      }

      return response.data.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to create service'
        );
      }
      throw error;
    }
  },

  updateService: async (id: number, data: ServiceUpdateInput): Promise<Service> => {
    try {
      const response = await api.put<ServiceResponse>(`/api/services/${id}`, data);

      if (!response.data.success || !response.data.data.success) {
        throw new Error('Failed to update service');
      }

      return response.data.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to update service'
        );
      }
      throw error;
    }
  },

  deleteService: async (id: number): Promise<void> => {
    try {
      const response = await api.delete<ServiceResponse>(`/api/services/${id}`);

      if (!response.data.success || !response.data.data.success) {
        throw new Error('Failed to delete service');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to delete service'
        );
      }
      throw error;
    }
  },
}; 