import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  IconButton,
  CircularProgress,
  Typography,
  Divider,
  Tooltip,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Treatment, TreatmentCreateInput, TreatmentUpdateInput } from '../services/treatmentService';
import ChatGPTIcon from '../assets/icons/chatgpt-icon.svg';
import { openaiService } from '../services/openaiService';
import { useSnackbar } from 'notistack';
import axios from 'axios';

interface StructuredData {
  '@context': string;
  '@type': string;
  name: string;
  description: string;
  url: string;
  image: string;
  procedureType: string;
  bodyLocation: string;
  preparation: string;
  followup: string;
  howPerformed: string;
  medicalSpecialty: string;
  relevantSpecialty: string;
  idealCandidates: string;
  contraindications: string;
  availableService: string;
  provider: string;
  estimatedCost: {
    '@type': string;
    currency: string;
    valueRange: string;
  };
  treatmentTime: string;
  recoveryTime: string;
  possibleComplications: string;
  benefitsOfProcedure: string;
  successRate: string;
  anesthesia: string;
  keywords: string[];
  mainEntityOfPage: string;
  publisher: {
    '@type': string;
    name: string;
    url: string;
    logo: string;
  };
  relatedLink: Array<{
    '@type': string;
    name: string;
    url: string;
  }>;
  mainEntity: {
    '@type': string;
    mainEntity: Array<{
      '@type': string;
      name: string;
      acceptedAnswer: {
        '@type': string;
        text: string;
      };
    }>;
  };
}

interface TreatmentFormProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: TreatmentCreateInput | TreatmentUpdateInput) => Promise<void>;
  initialData?: Treatment;
  loading?: boolean;
  mode: 'create' | 'edit';
}

export const TreatmentForm: React.FC<TreatmentFormProps> = ({
  open,
  onClose,
  onSubmit,
  initialData,
  loading = false,
  mode,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState<TreatmentCreateInput | TreatmentUpdateInput>({
    name: '',
    description: '',
    link: '',
    icon: '',
    seo_title: '',
    seo_description: '',
    slug: '',
    structured_data: null
  });

  const [rawStructuredData, setRawStructuredData] = useState<string>('');
  const [isGenerating, setIsGenerating] = useState(false);

  const generateSlug = (title: string): string => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, '') // Remove special characters
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/-+/g, '-') // Replace multiple hyphens with single hyphen
      .trim();
  };

  useEffect(() => {
    if (initialData) {
      setFormData({
        name: initialData.name,
        description: initialData.description,
        link: initialData.link,
        icon: initialData.icon,
        seo_title: initialData.seo_title,
        seo_description: initialData.seo_description,
        slug: initialData.slug,
        structured_data: initialData.structured_data
      });
      setRawStructuredData(initialData.structured_data ? JSON.stringify(initialData.structured_data, null, 2) : '');
    } else {
      setFormData({
        name: '',
        description: '',
        link: '',
        icon: '',
        seo_title: '',
        seo_description: '',
        slug: '',
        structured_data: null
      });
      setRawStructuredData('');
    }
  }, [initialData]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    const newSlug = generateSlug(newName);
    setFormData(prev => ({
      ...prev,
      name: newName,
      slug: newSlug,
      link: `treatments/${newSlug}`
    }));
  };

  const handleSlugChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSlug = generateSlug(e.target.value);
    setFormData(prev => ({
      ...prev,
      slug: newSlug,
      link: `treatments/${newSlug}`
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.name || !formData.description || !formData.link || 
        !formData.icon || !formData.seo_title || !formData.seo_description || !formData.slug) {
      enqueueSnackbar('Please fill in all required fields', { variant: 'error' });
      return;
    }

    let structuredData = null;
    if (rawStructuredData.trim()) {
      try {
        const parsedData = JSON.parse(rawStructuredData);
        if (validateStructuredData(parsedData)) {
          structuredData = parsedData;
        } else {
          enqueueSnackbar('Structured data is missing required fields or has invalid format', { variant: 'error' });
          return;
        }
      } catch (err) {
        console.error('Failed to parse structured data:', err);
        enqueueSnackbar('Invalid JSON format in Structured Data', { variant: 'error' });
        return;
      }
    }

    const formDataToSubmit: TreatmentCreateInput = {
      name: formData.name.trim(),
      description: formData.description.trim(),
      link: formData.link.trim(),
      icon: formData.icon.trim(),
      seo_title: formData.seo_title.trim(),
      seo_description: formData.seo_description.trim(),
      slug: formData.slug.trim(),
      structured_data: structuredData
    };

    console.log('Submitting form data:', JSON.stringify(formDataToSubmit, null, 2));
    try {
    await onSubmit(formDataToSubmit);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Server error details:', error.response?.data);
        enqueueSnackbar(error.response?.data?.message || 'Failed to submit form', { variant: 'error' });
      } else {
        console.error('Error submitting form:', error);
        enqueueSnackbar('Failed to submit form', { variant: 'error' });
      }
    }
  };

  const validateStructuredData = (data: any): data is StructuredData => {
    const requiredFields = [
      '@context', '@type', 'name', 'description', 'url', 'image',
      'procedureType', 'bodyLocation', 'preparation', 'followup',
      'howPerformed', 'medicalSpecialty', 'relevantSpecialty',
      'idealCandidates', 'contraindications', 'availableService',
      'provider', 'estimatedCost', 'treatmentTime', 'recoveryTime',
      'possibleComplications', 'benefitsOfProcedure', 'successRate',
      'anesthesia', 'keywords', 'mainEntityOfPage', 'publisher',
      'relatedLink', 'mainEntity'
    ];

    return requiredFields.every(field => field in data) &&
           typeof data.estimatedCost === 'object' &&
           typeof data.publisher === 'object' &&
           Array.isArray(data.relatedLink) &&
           typeof data.mainEntity === 'object' &&
           Array.isArray(data.mainEntity.mainEntity);
  };

  const handleStructuredDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setRawStructuredData(value);
    
    if (!value.trim()) {
      setFormData(prev => ({
        ...prev,
        structured_data: null
      }));
      return;
    }

    try {
      const jsonData = JSON.parse(value);
      if (validateStructuredData(jsonData)) {
        setFormData(prev => ({
          ...prev,
        structured_data: jsonData
        }));
      } else {
        console.warn('Invalid structured data format');
      }
    } catch (err) {
      console.warn('JSON Parse Error:', err);
    }
  };

  const generateTreatmentContent = async () => {
    if (!formData.name) {
      enqueueSnackbar('Please enter a treatment name first', { variant: 'error' });
      return;
    }

    setIsGenerating(true);
    try {
      const prompt = `As an expert medical content writer for a hair transplant platform, create comprehensive content for a treatment page about "${formData.name}". This content will be used on a treatment details page under the Treatments section.

Requirements for each section:

1. Technical Description (512 characters max):
- Focus on medical and technical aspects
- Explain the procedure professionally
- Include key benefits and advantages
- Maintain medical accuracy

2. Icon Suggestion:
- Suggest a simple, descriptive icon identifier
- Format: lowercase, hyphen-separated (e.g., dhi-method, fue-technique)
- Should represent the treatment clearly

3. SEO Title (60 characters max):
- Include main keyword naturally
- Be compelling and click-worthy
- Follow SEO best practices
- Format: "Primary Keyword - Secondary Keyword | HairTransplant.io"
- Example: "DHI Hair Transplant - Direct Hair Implantation | HairTransplant.io"

4. SEO Description (140 characters max):
- Summarize key benefits
- Include main keyword
- Be informative and engaging
- Focus on value proposition

5. Structured Data (JSON):
Follow this exact structure and fill in appropriate values for the treatment:
{
  "@context": "https://schema.org",
  "@type": "MedicalProcedure",
  "name": "[Treatment Name]",
  "description": "[Detailed description of the procedure]",
  "url": "https://hairtransplant.io/treatments/[slug]",
  "image": "https://hairtransplant.io/images/[slug].webp",
  "procedureType": "[Type of procedure, e.g., Minimally Invasive]",
  "bodyLocation": "Scalp",
  "preparation": "[Required preparation steps]",
  "followup": "[Post-procedure care and check-ups]",
  "howPerformed": "[Detailed step-by-step procedure description]",
  "medicalSpecialty": "Dermatology",
  "relevantSpecialty": "Plastic Surgery",
  "idealCandidates": "[Who is suitable for this treatment]",
  "contraindications": "[Who should not undergo this treatment]",
  "availableService": "Hair Transplant Clinics",
  "provider": "Certified Hair Transplant Specialists",
  "estimatedCost": {
    "@type": "MonetaryAmount",
    "currency": "USD",
    "valueRange": "[Cost range]"
  },
  "treatmentTime": "[Duration of procedure]",
  "recoveryTime": "[Recovery period]",
  "possibleComplications": "[Potential risks and complications]",
  "benefitsOfProcedure": "[Main advantages and benefits]",
  "successRate": "[Success rate percentage]",
  "anesthesia": "[Type of anesthesia used]",
  "keywords": ["Hair Transplant", "[Treatment specific keywords]"],
  "mainEntityOfPage": "https://hairtransplant.io/treatments/[slug]",
  "publisher": {
    "@type": "Organization",
    "name": "HairTransplant.io",
    "url": "https://hairtransplant.io",
    "logo": "https://hairtransplant.io/logo.webp"
  },
  "relatedLink": [
    {
      "@type": "MedicalProcedure",
      "name": "FUE Hair Transplant",
      "url": "https://hairtransplant.io/treatments/fue-hair-transplant"
    },
    {
      "@type": "MedicalProcedure",
      "name": "DHI Hair Transplant",
      "url": "https://hairtransplant.io/treatments/dhi-hair-transplant"
    },
    {
      "@type": "MedicalProcedure",
      "name": "PRP Therapy",
      "url": "https://hairtransplant.io/treatments/prp-therapy"
    }
  ],
  "mainEntity": {
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Is [treatment name] painful?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "[Detailed answer about pain and comfort during procedure]"
        }
      },
      {
        "@type": "Question",
        "name": "How long does it take to see results from [treatment name]?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "[Timeline for visible results and full recovery]"
        }
      },
      {
        "@type": "Question",
        "name": "Who is a good candidate for [treatment name]?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "[Description of ideal candidates and requirements]"
        }
      },
      {
        "@type": "Question",
        "name": "What are the advantages of [treatment name] over traditional methods?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "[Key benefits and advantages compared to alternatives]"
        }
      },
      {
        "@type": "Question",
        "name": "Are the results of [treatment name] permanent?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "[Information about long-term results and maintenance]"
        }
      }
    ]
  }
}

Please provide the response in the following JSON format:
{
  "description": "Technical description here...",
  "icon": "icon-identifier-here",
  "seoTitle": "SEO Title here...",
  "seoDescription": "SEO Description here...",
  "structuredData": [STRUCTURED_DATA_JSON_AS_SPECIFIED_ABOVE]
}`;

      const response = await openaiService.generateTreatmentContent(prompt);
      console.log('OpenAI Response:', response);

      try {
        const parsedResponse = JSON.parse(response);
        if (!parsedResponse.description || !parsedResponse.icon || 
            !parsedResponse.seoTitle || !parsedResponse.seoDescription || 
            !parsedResponse.structuredData) {
          throw new Error('Missing required fields in generated content');
        }

        // Parse and validate structured data separately
        const structuredData = typeof parsedResponse.structuredData === 'string' 
          ? JSON.parse(parsedResponse.structuredData) 
          : parsedResponse.structuredData;

        if (!validateStructuredData(structuredData)) {
          throw new Error('Invalid structured data format in generated content');
        }

        // Update form with generated content
        setFormData(prev => ({
          ...prev,
          description: parsedResponse.description,
          icon: parsedResponse.icon,
          seo_title: parsedResponse.seoTitle,
          seo_description: parsedResponse.seoDescription,
          structured_data: structuredData
        }));

        // Update raw structured data display
        setRawStructuredData(JSON.stringify(structuredData, null, 2));

        enqueueSnackbar('Content generated successfully', { variant: 'success' });
      } catch (parseError) {
        console.error('Error parsing generated content:', parseError);
        if (response) {
          console.error('Invalid JSON:', response);
        }
        enqueueSnackbar('Failed to parse generated content. The response may be incomplete. Please try again.', { 
          variant: 'error',
          autoHideDuration: 6000
        });
      }
    } catch (error) {
      console.error('Error generating content:', error);
      if (error instanceof Error) {
        enqueueSnackbar(`Failed to generate content: ${error.message}`, { 
          variant: 'error',
          autoHideDuration: 6000
        });
      } else {
        enqueueSnackbar('Failed to generate content. Please try again.', { 
          variant: 'error',
          autoHideDuration: 6000
        });
      }
    } finally {
      setIsGenerating(false);
    }
  };

  const handleClose = () => {
    setFormData({
      name: '',
      description: '',
      link: '',
      icon: '',
      seo_title: '',
      seo_description: '',
      slug: '',
      structured_data: null
    });
    setRawStructuredData('');
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      maxWidth="md" 
      fullWidth
      keepMounted={false}
      disableEnforceFocus
      disablePortal
      PaperProps={{
        sx: {
          height: '90vh',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <DialogTitle
        sx={{
          p: 2,
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: 'background.paper',
          position: 'sticky',
          top: 0,
          zIndex: 1
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            {mode === 'create' ? 'Create Treatment' : 'Edit Treatment'}
          </Typography>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <DialogContent 
          dividers
          sx={{
            p: 2,
            overflowY: 'auto',
            flex: 1
          }}
        >
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label="Name"
              value={formData.name}
              onChange={handleNameChange}
              required
              fullWidth
            />
            <TextField
              label="Slug"
              value={formData.slug}
              onChange={handleSlugChange}
              required
              fullWidth
              placeholder="e.g. dhi-hair-transplant"
              helperText="URL-friendly version of the name (automatically generated but can be edited)"
            />
            <TextField
              label="Link"
              value={formData.link}
              InputProps={{
                readOnly: true,
                sx: { bgcolor: 'action.hover' }
              }}
              required
              fullWidth
              helperText="Auto-generated from slug with treatments/ prefix"
            />
            <TextField
              label="Description"
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              required
              fullWidth
              multiline
              rows={4}
            />
            <TextField
              label="Icon"
              value={formData.icon}
              onChange={(e) => setFormData({ ...formData, icon: e.target.value })}
              required
              fullWidth
              helperText="Icon identifier (e.g., dhi-icon)"
            />

            <Divider sx={{ my: 2 }} />

            {/* SEO Information */}
            <Typography variant="subtitle1" color="primary" gutterBottom>
              SEO Information
            </Typography>
            <TextField
              label="SEO Title"
              value={formData.seo_title}
              onChange={(e) => setFormData({ ...formData, seo_title: e.target.value })}
              required
              fullWidth
            />
            <TextField
              label="SEO Description"
              value={formData.seo_description}
              onChange={(e) => setFormData({ ...formData, seo_description: e.target.value })}
              required
              fullWidth
              multiline
              rows={2}
            />

            <Divider sx={{ my: 2 }} />

            {/* Structured Data */}
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Structured Data (JSON)
            </Typography>
            <TextField
              label="Structured Data"
              value={rawStructuredData}
              onChange={handleStructuredDataChange}
              fullWidth
              multiline
              rows={10}
              helperText="Enter your structured data JSON"
              InputProps={{
                sx: { fontFamily: 'monospace' }
              }}
            />
            <Typography variant="caption" color="text.secondary">
              Example:
              {`
{
  "@context": "https://schema.org",
  "@type": "MedicalProcedure",
  "name": "DHI Hair Transplant",
  "description": "Direct Hair Implantation (DHI) is an advanced hair transplant technique...",
  "url": "https://hairtransplant.io/treatments/dhi-hair-transplant",
  "image": "https://hairtransplant.io/images/dhi-hair-transplant.webp",
  "procedureType": "Minimally Invasive",
  "bodyLocation": "Scalp",
  "preparation": "Initial consultation, medical evaluation...",
  "followup": "Regular check-ups, following post-operative care instructions...",
  "howPerformed": "Using specialized Choi implanter pens...",
  "medicalSpecialty": "Dermatology",
  "relevantSpecialty": "Plastic Surgery",
  "idealCandidates": "Men and women with sufficient donor hair...",
  "contraindications": "Severe scalp conditions, unrealistic expectations...",
  "availableService": "Hair Transplant Clinics",
  "provider": "Certified Hair Transplant Specialists",
  "estimatedCost": {
    "@type": "MonetaryAmount",
    "currency": "USD",
    "valueRange": "3000-7000"
  },
  "treatmentTime": "6-8 hours",
  "recoveryTime": "2 weeks initial, full results in 12 months",
  "possibleComplications": "Mild swelling, temporary numbness...",
  "benefitsOfProcedure": "Natural results, minimal scarring...",
  "successRate": "95%",
  "anesthesia": "Local anesthesia",
  "keywords": ["Hair Transplant", "DHI", "Direct Hair Implantation"],
  "mainEntityOfPage": "https://hairtransplant.io/treatments/dhi-hair-transplant",
  "publisher": {
    "@type": "Organization",
    "name": "HairTransplant.io",
    "url": "https://hairtransplant.io",
    "logo": "https://hairtransplant.io/logo.webp"
  },
  "relatedLink": [
    {
      "@type": "MedicalProcedure",
      "name": "FUE Hair Transplant",
      "url": "https://hairtransplant.io/treatments/fue-hair-transplant"
    },
    {
      "@type": "MedicalProcedure",
      "name": "DHI Hair Transplant",
      "url": "https://hairtransplant.io/treatments/dhi-hair-transplant"
    },
    {
      "@type": "MedicalProcedure",
      "name": "PRP Therapy",
      "url": "https://hairtransplant.io/treatments/prp-therapy"
    }
  ],
  "mainEntity": {
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Is DHI hair transplant painful?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "No, DHI is performed under local anesthesia making it virtually painless..."
        }
      },
      {
        "@type": "Question",
        "name": "How long does it take to see results from DHI hair transplant?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Initial growth starts at 3-4 months, with full results visible at 12-18 months..."
        }
      }
    ]
  }
}
              `}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions 
          sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            p: 2,
            borderTop: '1px solid',
            borderColor: 'divider',
            bgcolor: 'background.paper',
            position: 'sticky',
            bottom: 0,
            zIndex: 1
          }}
        >
          <Box>
            {formData.name && (
              <Tooltip title="Generate content with AI">
                <IconButton
                  onClick={generateTreatmentContent}
                  disabled={isGenerating || !formData.name}
                  color="primary"
                  sx={{
                    bgcolor: 'primary.lighter',
                    '&:hover': { bgcolor: 'primary.light' },
                    mr: 1
                  }}
                >
                  {isGenerating ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Box
                      component="img"
                      src={ChatGPTIcon}
                      sx={{ width: 24, height: 24 }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Box>
            <Button onClick={handleClose} disabled={loading || isGenerating}>
              Cancel
            </Button>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              disabled={loading || isGenerating}
              sx={{ ml: 1 }}
            >
              {loading ? 'Saving...' : mode === 'create' ? 'Create' : 'Update'}
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
}; 