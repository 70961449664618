import axios from 'axios';
import { authService } from './authService';

export interface Category {
  id: number;
  name: string;
  slug: string;
  description: string;
  parent_id: number | null;
  sort_order: number;
  is_highlighted: boolean;
  image: string;
  seo_title: string;
  seo_description: string;
  structured_data: string;
  is_deleted: number;
  created_at: string;
  updated_at: string;
}

export interface CategoryCreateInput {
  name: string;
  description: string;
  slug: string;
  parent_id: number | null;
  sort_order: number;
  is_highlighted: boolean;
  image: string;
  seo_title: string;
  seo_description: string;
  structured_data: string;
}

export interface CategoryUpdateInput extends CategoryCreateInput {}

interface CategoryResponse {
  success: boolean;
  data: Category;
  message?: string;
}

interface CategoryListResponse {
  success: boolean;
  data: Category[];
  meta: {
    current_page: number;
    per_page: number;
    total: number;
    total_pages: number;
  };
  message?: string;
}

const BASE_URL = import.meta.env.VITE_API_URL || 'http://localhost:3232';

// Create axios instance with base configuration
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
});

// Add request interceptor for authentication
api.interceptors.request.use(
  (config) => {
    const token = authService.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const categoryService = {
  getCategories: async (signal?: AbortSignal) => {
    try {
      const response = await api.get<CategoryListResponse>('/api/categories', {
        signal
      });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to fetch categories');
      }

      return {
        categories: response.data.data,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.name === 'CanceledError' || error.code === 'ERR_CANCELED') {
          throw new Error('Request was cancelled');
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to fetch categories'
        );
      }
      throw error;
    }
  },

  getCategory: async (id: number, signal?: AbortSignal): Promise<Category> => {
    try {
      const response = await api.get<CategoryResponse>(`/api/categories/${id}`, {
        signal
      });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to fetch category');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.name === 'CanceledError' || error.code === 'ERR_CANCELED') {
          throw new Error('Request was cancelled');
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to fetch category'
        );
      }
      throw error;
    }
  },

  createCategory: async (data: CategoryCreateInput): Promise<Category> => {
    try {
      // Ensure all required fields have proper values
      const formattedData = {
        ...data,
        parent_id: data.parent_id === 0 ? null : data.parent_id, // Convert 0 to null for main categories
        sort_order: data.sort_order || 0,
        is_highlighted: data.is_highlighted || false,
        image: data.image || '',
        seo_title: data.seo_title || '',
        seo_description: data.seo_description || '',
        structured_data: data.structured_data || '',
      };

      const response = await api.post<CategoryResponse>('/api/categories', formattedData);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to create category');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to create category'
        );
      }
      throw error;
    }
  },

  updateCategory: async (id: number, data: CategoryUpdateInput): Promise<Category> => {
    try {
      // Ensure all required fields have proper values
      const formattedData = {
        ...data,
        parent_id: data.parent_id === 0 ? null : data.parent_id, // Convert 0 to null for main categories
        sort_order: data.sort_order || 0,
        is_highlighted: data.is_highlighted || false,
        image: data.image || '',
        seo_title: data.seo_title || '',
        seo_description: data.seo_description || '',
        structured_data: data.structured_data || '',
      };

      const response = await api.put<CategoryResponse>(`/api/categories/${id}`, formattedData);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to update category');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to update category'
        );
      }
      throw error;
    }
  },

  deleteCategory: async (id: number): Promise<void> => {
    try {
      const response = await api.delete<CategoryResponse>(`/api/categories/${id}`);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to delete category');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to delete category'
        );
      }
      throw error;
    }
  }
}; 