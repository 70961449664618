import { axiosInstance } from './axiosInstance';
import axios from 'axios';

const BASE_URL = import.meta.env.VITE_API_URL;
if (!BASE_URL) {
  throw new Error('VITE_API_URL is not defined in environment variables');
}

export interface Image {
  id: number;
  clinic_id: number;
  url: string;
  view: 'GALLERY' | 'BEFORE_AFTER' | 'PROFILE';
  rank: number;
  is_deleted: number;
  created_at: string;
  updated_at: string;
}

export interface ImageCreateInput {
  file: File;
  clinic_id: number;
  view: 'GALLERY' | 'BEFORE_AFTER' | 'PROFILE';
  rank: number;
}

export type ImageUpdateInput = Partial<ImageCreateInput>;

interface GetImagesParams {
  page?: number;
  limit?: number;
  search?: string;
  view?: 'GALLERY' | 'BEFORE_AFTER' | 'PROFILE';
}

interface GetImagesResponse {
  success: boolean;
  data: Image[];
  meta: {
    current_page: number;
    per_page: number;
    total: number;
    total_pages: number;
  };
}

interface ImageResponse {
  success: boolean;
  data: Image;
}

class ImagesService {
  private getFullImageUrl(path: string): string {
    if (path.startsWith('http')) return path;
    const cleanPath = path.startsWith('/') ? path.slice(1) : path;
    const cleanBaseUrl = BASE_URL.endsWith('/') ? BASE_URL.slice(0, -1) : BASE_URL;
    return `${cleanBaseUrl}/${cleanPath}`;
  }

  async getImages(params: GetImagesParams = {}, signal?: AbortSignal) {
    const { page = 1, limit = 10, search = '', view } = params;
    const queryParams = new URLSearchParams({
      page: page.toString(),
      per_page: limit.toString(),
      ...(search && { search }),
      ...(view && { view }),
    });

    try {
      const response = await axiosInstance.get<GetImagesResponse>(`/api/images?${queryParams}`, {
        signal,
      });
      
      if (!response.data.success) {
        throw new Error('Failed to fetch images');
      }

      const images = response.data.data.map(image => ({
        ...image,
        url: this.getFullImageUrl(image.url)
      }));

      return {
        images,
        total: response.data.meta.total,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.name === 'CanceledError' || error.code === 'ERR_CANCELED') {
          throw new Error('Request was cancelled');
        }
        
        if (error.response?.status === 401) {
          throw new Error('Unauthorized access');
        }
      }
      throw error;
    }
  }

  async getImage(id: number) {
    try {
      const response = await axiosInstance.get<ImageResponse>(`/api/images/${id}`);
      
      if (!response.data.success) {
        throw new Error('Failed to fetch image');
      }

      return {
        ...response.data.data,
        url: this.getFullImageUrl(response.data.data.url)
      };
    } catch (error) {
      console.error('Error fetching image:', error);
      throw error;
    }
  }

  async createImage(data: ImageCreateInput) {
    try {
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('clinic_id', data.clinic_id.toString());
      formData.append('view', data.view);
      formData.append('rank', data.rank.toString());

      const response = await axiosInstance.post<ImageResponse>('/api/images', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      if (!response.data.success) {
        throw new Error('Failed to create image');
      }

      return response.data.data;
    } catch (error) {
      console.error('Error creating image:', error);
      throw error;
    }
  }

  async updateImage(id: number, data: ImageUpdateInput) {
    try {
      const response = await axiosInstance.put<ImageResponse>(`/api/images/${id}`, data);
      
      if (!response.data.success) {
        throw new Error('Failed to update image');
      }

      return response.data.data;
    } catch (error) {
      console.error('Error updating image:', error);
      throw error;
    }
  }

  async deleteImage(id: number) {
    try {
      const response = await axiosInstance.delete<{ success: boolean }>(`/api/images/${id}`);
      
      if (!response.data.success) {
        throw new Error('Failed to delete image');
      }

      return true;
    } catch (error) {
      console.error('Error deleting image:', error);
      throw error;
    }
  }

  async uploadImage(file: File) {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch(`${BASE_URL}/api/images/upload`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload image');
      }

      const data = await response.json();
      return data.url;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  }
}

export const imagesService = new ImagesService(); 