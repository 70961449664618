import { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Alert,
  Box,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  Autocomplete,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { DataTable, Column } from '../components/DataTable';
import { clinicLoginsService, ClinicLogin, ClinicLoginCreateInput, ClinicLoginUpdateInput } from '../services/clinicLoginsService';
import { clinicService, Clinic } from '../services/clinicService';

const columns: Column[] = [
  { 
    id: 'clinic_id', 
    label: 'Clinic ID', 
    minWidth: 100,
    format: (value: number) => `${value}`,
  },
  { 
    id: 'clinic_title', 
    label: 'Clinic Name', 
    minWidth: 250,
  },
  { 
    id: 'clinic_mail', 
    label: 'Email', 
    minWidth: 200 
  },
  { 
    id: 'status', 
    label: 'Status', 
    minWidth: 100,
    format: (value: string) => (
      <Chip
        label={value.charAt(0).toUpperCase() + value.slice(1)}
        color={value === 'active' ? 'success' : 'default'}
        size="small"
      />
    ),
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 100,
    align: 'right',
  },
];

interface ClinicLoginFormProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: ClinicLoginCreateInput | ClinicLoginUpdateInput) => Promise<void>;
  initialData?: ClinicLogin;
  loading?: boolean;
  mode: 'create' | 'edit';
}

const ClinicLoginForm = ({
  open,
  onClose,
  onSubmit,
  initialData,
  loading = false,
  mode,
}: ClinicLoginFormProps) => {
  const [formData, setFormData] = useState<ClinicLoginCreateInput>({
    clinic_id: 0,
    clinic_mail: '',
    password: '',
  });
  const [clinics, setClinics] = useState<Clinic[]>([]);
  const [selectedClinic, setSelectedClinic] = useState<Clinic | null>(null);
  const [loadingClinics, setLoadingClinics] = useState(false);

  useEffect(() => {
    if (open && initialData) {
      setFormData({
        clinic_id: initialData.clinic_id,
        clinic_mail: initialData.clinic_mail,
        password: '',
      });
    } else if (open) {
      setFormData({
        clinic_id: 0,
        clinic_mail: '',
        password: '',
      });
    } else {
      setFormData({
        clinic_id: 0,
        clinic_mail: '',
        password: '',
      });
      setSelectedClinic(null);
      setClinics([]);
    }
  }, [open, initialData]);

  const searchClinics = async (query: string) => {
    try {
      setLoadingClinics(true);
      const response = await clinicService.getClinics({ 
        page: 1, 
        per_page: 10, 
        search: query 
      });
      setClinics(response.data.clinics);
    } catch (error) {
      console.error('Failed to search clinics:', error);
      setClinics([]);
    } finally {
      setLoadingClinics(false);
    }
  };

  const handleChange = (field: keyof ClinicLoginCreateInput) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleClinicChange = (_: React.SyntheticEvent, value: Clinic | null) => {
    setSelectedClinic(value);
    if (value) {
      setFormData(prev => ({
        ...prev,
        clinic_id: Number(value.id),
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        clinic_id: 0,
      }));
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    console.log('Form submitted with data:', formData);
    
    if (mode === 'create' && formData.password.length < 6) {
      return; // Don't submit if password is too short in create mode
    }
    
    if (mode === 'edit' && !formData.password) {
      const { password, ...dataWithoutPassword } = formData;
      await onSubmit(dataWithoutPassword);
    } else {
      await onSubmit(formData);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{mode === 'create' ? 'Create Clinic Login' : 'Edit Clinic Login'}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            {mode === 'create' && (
              <Autocomplete
                options={clinics}
                getOptionLabel={(option) => option.title}
                value={selectedClinic}
                onChange={handleClinicChange}
                loading={loadingClinics}
                onInputChange={(_, newInputValue) => {
                  if (newInputValue) {
                    searchClinics(newInputValue);
                  } else {
                    setClinics([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Clinic"
                    required
                    disabled={loading}
                  />
                )}
                noOptionsText="Type to search clinics..."
              />
            )}
            <TextField
              label="Email"
              type="email"
              value={formData.clinic_mail}
              onChange={handleChange('clinic_mail')}
              required
              fullWidth
              disabled={loading}
            />
            <TextField
              label="Password"
              type="password"
              value={formData.password}
              onChange={handleChange('password')}
              required={mode === 'create'}
              helperText={mode === 'edit' ? 'Leave empty to keep current password' : 'Minimum 6 characters'}
              fullWidth
              disabled={loading}
              inputProps={{ minLength: 6 }}
              error={mode === 'create' && formData.password.length > 0 && formData.password.length < 6}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={loading}>
            {mode === 'create' ? 'Create' : 'Save'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export const ClinicLoginsPage = () => {
  const [clinicLogins, setClinicLogins] = useState<ClinicLogin[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [total, setTotal] = useState(0);
  const [fetchParams, setFetchParams] = useState({
    page: 0,
    rowsPerPage: 10,
    searchQuery: '',
  });
  const [formOpen, setFormOpen] = useState(false);
  const [selectedClinicLogin, setSelectedClinicLogin] = useState<ClinicLogin | undefined>(undefined);
  const [formMode, setFormMode] = useState<'create' | 'edit'>('create');

  useEffect(() => {
    const controller = new AbortController();
    let isMounted = true;

    const fetchData = async () => {
      try {
        setLoading(true);
        setError(undefined);
        
        const { clinicLogins: data, total: totalCount } = await clinicLoginsService.getClinicLogins({
          page: fetchParams.page + 1,
          limit: fetchParams.rowsPerPage,
          search: fetchParams.searchQuery,
        }, controller.signal);

        if (!isMounted) return;

        // Fetch all unique clinic IDs at once
        const uniqueClinicIds = [...new Set(data.map(login => login.clinic_id))];
        const clinicsMap = new Map();
        
        await Promise.all(
          uniqueClinicIds.map(async (clinicId) => {
            try {
              const clinic = await clinicService.getClinic(clinicId.toString());
              clinicsMap.set(clinicId, clinic);
            } catch (error) {
              console.error(`Failed to fetch clinic details for ID ${clinicId}:`, error);
              clinicsMap.set(clinicId, { title: 'Unknown Clinic' });
            }
          })
        );

        if (!isMounted) return;

        // Enrich data with clinic details from the map
        const enrichedData = data.map(login => ({
          ...login,
          clinic_title: clinicsMap.get(login.clinic_id)?.title || 'Unknown Clinic',
        }));
        
        setClinicLogins(enrichedData);
        setTotal(totalCount);
      } catch (err) {
        if (err instanceof Error && err.message === 'Request was cancelled') {
          return;
        }
        if (!isMounted) return;
        setError(err instanceof Error ? err.message : 'An error occurred while fetching clinic logins');
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      controller.abort();
      isMounted = false;
    };
  }, [fetchParams]);

  const handleCreate = async (data: ClinicLoginCreateInput) => {
    try {
      setLoading(true);
      setError(undefined);
      await clinicLoginsService.createClinicLogin(data);
      setFormOpen(false);
      setFetchParams(prev => ({ ...prev })); // Trigger refetch
    } catch (err) {
      console.error('Error creating clinic login:', err);
      setError(err instanceof Error ? err.message : 'An error occurred while creating clinic login');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (data: ClinicLoginUpdateInput) => {
    try {
      if (!selectedClinicLogin) return;
      setLoading(true);
      setError(undefined);
      await clinicLoginsService.updateClinicLogin(selectedClinicLogin.id, data);
      setFormOpen(false);
      setSelectedClinicLogin(undefined);
      setFetchParams(prev => ({ ...prev })); // Trigger refetch
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred while updating clinic login');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      setLoading(true);
      setError(undefined);
      await clinicLoginsService.deleteClinicLogin(id);
      setFetchParams(prev => ({ ...prev })); // Trigger refetch
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred while deleting clinic login');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (clinicLogin: ClinicLogin) => {
    setSelectedClinicLogin(clinicLogin);
    setFormMode('edit');
    setFormOpen(true);
  };

  const handleFormClose = () => {
    setFormOpen(false);
    setSelectedClinicLogin(undefined);
    setFormMode('create');
  };

  const handleFormSubmit = async (data: ClinicLoginCreateInput | ClinicLoginUpdateInput) => {
    if (formMode === 'create') {
      await handleCreate(data as ClinicLoginCreateInput);
    } else {
      await handleUpdate(data as ClinicLoginUpdateInput);
    }
  };

  const handlePageChange = (newPage: number) => {
    setFetchParams(prev => ({
      ...prev,
      page: newPage,
    }));
  };

  const handleSearchChange = (value: string) => {
    setFetchParams(prev => ({
      ...prev,
      searchQuery: value,
      page: 0,
    }));
  };

  const handleRowsPerPageChange = (newLimit: number) => {
    setFetchParams(prev => ({
      ...prev,
      rowsPerPage: newLimit,
      page: 0,
    }));
  };

  const renderActions = (item: ClinicLogin) => (
    <Box>
      <Tooltip title="Edit">
        <IconButton size="small" onClick={() => handleEdit(item)}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton size="small" onClick={() => handleDelete(item.id)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h4" component="h1">
          Clinic Logins
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setFormMode('create');
            setFormOpen(true);
          }}
        >
          Create Clinic Login
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <DataTable
        columns={columns}
        rows={clinicLogins.map(item => ({
          ...item,
          actions: renderActions(item)
        }))}
        totalRows={total}
        page={fetchParams.page}
        rowsPerPage={fetchParams.rowsPerPage}
        loading={loading}
        error={error}
        searchValue={fetchParams.searchQuery}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onSearchChange={handleSearchChange}
      />

      <ClinicLoginForm
        open={formOpen}
        onClose={handleFormClose}
        onSubmit={handleFormSubmit}
        initialData={selectedClinicLogin}
        loading={loading}
        mode={formMode}
      />
    </Container>
  );
}; 