import { useEffect, useState, useCallback } from 'react';
import {
  Container,
  Typography,
  Alert,
  Box,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Grid,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material';
import { 
  Add as AddIcon, 
  Edit as EditIcon, 
  Delete as DeleteIcon,
  AddCircleOutline as AddSubIcon,
  Category as CategoryIcon,
} from '@mui/icons-material';
import { CategoryForm } from '../components/CategoryForm';
import { categoryService, Category, CategoryCreateInput, CategoryUpdateInput } from '../services/categoryService';

export const CategoriesPage = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formOpen, setFormOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>();
  const [selectedParentId, setSelectedParentId] = useState<number>(0);
  const [formLoading, setFormLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState<Category | undefined>();

  const fetchCategories = useCallback(() => {
    const controller = new AbortController();
    let isMounted = true;

    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        const { categories: fetchedCategories } = await categoryService.getCategories(controller.signal);
        
        if (isMounted) {
          setCategories(fetchedCategories);
        }
      } catch (err) {
        if (err instanceof Error && (err.name === 'CanceledError' || err.message === 'Request was cancelled')) {
          return;
        }
        const errorMessage = err instanceof Error ? err.message : 'Failed to fetch categories';
        if (isMounted) {
          setError(errorMessage);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      controller.abort();
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const cleanup = fetchCategories();
    return cleanup;
  }, [fetchCategories]);

  const handleCreateCategory = async (data: CategoryCreateInput) => {
    try {
      setFormLoading(true);
      await categoryService.createCategory({
        ...data,
        parent_id: selectedParentId
      });
      setFormOpen(false);
      setSelectedParentId(0);
      setSelectedCategory(undefined);
      fetchCategories();
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to create category';
      setError(errorMessage);
    } finally {
      setFormLoading(false);
    }
  };

  const handleUpdateCategory = async (data: CategoryUpdateInput) => {
    if (!selectedCategory) return;

    try {
      setFormLoading(true);
      await categoryService.updateCategory(selectedCategory.id, data);
      setFormOpen(false);
      setSelectedCategory(undefined);
      fetchCategories();
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to update category';
      setError(errorMessage);
    } finally {
      setFormLoading(false);
    }
  };

  const handleDeleteCategory = async () => {
    if (!categoryToDelete) return;

    try {
      setFormLoading(true);
      await categoryService.deleteCategory(categoryToDelete.id);
      setDeleteDialogOpen(false);
      setCategoryToDelete(undefined);
      fetchCategories();
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to delete category';
      setError(errorMessage);
    } finally {
      setFormLoading(false);
    }
  };

  const handleFormSubmit = async (data: CategoryCreateInput | CategoryUpdateInput) => {
    if (selectedCategory) {
      await handleUpdateCategory(data as CategoryUpdateInput);
    } else {
      await handleCreateCategory(data as CategoryCreateInput);
    }
  };

  const handleAddSubCategory = (parentCategory: Category) => {
    setSelectedParentId(parentCategory.id);
    setSelectedCategory(undefined);
    setFormOpen(true);
  };

  // Group categories by parent_id
  const categoriesByParent = categories.reduce((acc, category) => {
    const parentId = category.parent_id || 0;
    if (!acc[parentId]) {
      acc[parentId] = [];
    }
    acc[parentId].push(category);
    return acc;
  }, {} as Record<number, Category[]>);

  const mainCategories = categoriesByParent[0] || [];

  const renderCategoryCard = (category: Category) => {
    const subCategories = categoriesByParent[category.id] || [];

    return (
      <Card key={category.id} sx={{ mb: 2 }}>
        <CardContent sx={{ py: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CategoryIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6" component="div">
                  {category.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" color="text.secondary" sx={{ 
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}>
                {category.description}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              {subCategories.length > 0 && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {subCategories.map((subCat) => (
                    <Chip
                      key={subCat.id}
                      label={subCat.name}
                      variant="outlined"
                      size="small"
                      onDelete={() => setCategoryToDelete(subCat)}
                      onClick={() => {
                        setSelectedCategory(subCat);
                        setFormOpen(true);
                      }}
                    />
                  ))}
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={2}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <Tooltip title="Add Sub Category">
                  <IconButton size="small" onClick={() => handleAddSubCategory(category)}>
                    <AddSubIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSelectedCategory(category);
                      setFormOpen(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setCategoryToDelete(category);
                      setDeleteDialogOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  return (
    <Container maxWidth={false} sx={{ p: 3 }}>
      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h4" component="h1">
            Categories
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setSelectedCategory(undefined);
              setSelectedParentId(0);
              setFormOpen(true);
            }}
          >
            Create Main Category
          </Button>
        </Box>
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {mainCategories.map((category) => renderCategoryCard(category))}
        </Box>
      )}

      <CategoryForm
        open={formOpen}
        onClose={() => {
          setFormOpen(false);
          setSelectedCategory(undefined);
          setSelectedParentId(0);
        }}
        onSubmit={handleFormSubmit}
        initialData={selectedCategory}
        loading={formLoading}
        mode={selectedCategory ? 'edit' : 'create'}
        categories={categories}
        parent_id={selectedParentId}
      />

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete Category</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete {categoryToDelete?.name}?
            {categoriesByParent[categoryToDelete?.id || 0]?.length > 0 && (
              <Box sx={{ mt: 2, color: 'error.main' }}>
                Warning: This category has sub-categories that will also be deleted.
              </Box>
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} disabled={formLoading}>
            Cancel
          </Button>
          <Button
            onClick={handleDeleteCategory}
            color="error"
            disabled={formLoading}
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}; 