import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItemIcon,
  ListItemButton,
  Tooltip,
  Box,
} from '@mui/material';
import {
  Logout,
  Category as CategoryIcon,
  VpnKey as ClinicLoginsIcon,
  Settings as SettingsIcon,
  MedicalInformation as ClinicRequestsIcon,
  ContactPage as LeadsIcon,
  LocalOffer as OffersIcon,
  Healing as HealingIcon,
  MedicalServices as ServicesIcon,
  LocalHospital as ClinicsIcon,
  Image as ImagesIcon,
  Article as PagesIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

const DRAWER_WIDTH = 58;

interface SidebarProps {
  onLogout: () => void;
}

// Logo component
const Logo = () => {
  const navigate = useNavigate();
  
  return (
    <Box 
      sx={{ 
        p: 1, 
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
          opacity: 0.8,
        },
      }}
      onClick={() => navigate('/')}
    >
      <svg width="40" height="40" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M29 58C45.0163 58 58 45.0163 58 29C58 12.9837 45.0163 0 29 0C12.9837 0 0 12.9837 0 29C0 45.0163 12.9837 58 29 58Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M44.9856 41.2126C44.8215 41.7958 44.621 42.3974 44.3659 43.0171C44.1107 43.6368 43.8008 44.1837 43.3998 44.6758C43.017 45.1679 42.5613 45.5507 42.0327 45.8606C41.5041 46.1704 40.8844 46.3345 40.1371 46.3345C39.4809 46.3345 38.9523 46.2251 38.533 45.9882C38.132 45.7512 37.8222 45.4413 37.6034 45.0221C37.3847 44.6211 37.2389 44.1654 37.166 43.6551C37.0931 43.1447 37.0566 42.6161 37.0566 42.0328V29.1277H40.611C41.176 29.1277 41.65 28.5991 41.65 27.9429C41.65 27.2867 41.176 26.7581 40.611 26.7581H37.0566V16.6601C37.0566 14.7097 35.4708 13.1057 33.5022 13.1057C31.5337 13.1057 29.9479 14.6915 29.9479 16.6601V26.7581H23.1126V16.6783C23.1126 14.728 21.5268 13.1239 19.5582 13.1239C17.5896 13.1239 16.0038 14.7097 16.0038 16.6783V26.7581H12.413C11.8479 26.7581 11.374 27.2867 11.374 27.9429C11.374 28.5991 11.8479 29.1277 12.413 29.1277H16.0038V39.9913C16.0038 41.9417 17.5896 43.5457 19.5582 43.5457C21.5268 43.5457 23.1126 41.9599 23.1126 39.9913V29.1277H29.9479V40.4652C29.9479 41.1579 29.9843 41.9234 30.0572 42.7254C30.1302 43.5457 30.44 44.293 30.9504 44.9857C31.479 45.6783 32.281 46.2616 33.3929 46.7355C34.5048 47.2094 36.0541 47.4282 38.0774 47.4282C38.8065 47.4282 39.5902 47.3552 40.4105 47.2276C41.2307 47.1001 41.9963 46.8084 42.7071 46.3892C43.418 45.9699 44.056 45.3502 44.621 44.5664C45.1861 43.7644 45.5871 42.7072 45.8058 41.3948L44.9309 41.2126H44.9856Z" fill="white"/>
      </svg>
    </Box>
  );
};

export const Sidebar: React.FC<SidebarProps> = ({ onLogout }) => {
  const location = useLocation();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
          borderRight: '1px solid rgba(0, 0, 0, 0.12)',
          backgroundColor: 'background.paper',
        },
      }}
    >
      <Box sx={{ overflow: 'auto', height: '100%', display: 'flex', flexDirection: 'column' }}>
        {/* Logo */}
        <Box sx={{ 
          p: 1, 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          minHeight: '64px',
        }}>
          <Logo />
        </Box>

        {/* Main Menu Items */}
        <List>
          {/* First Group */}
          <Tooltip title="Leads" placement="right">
            <ListItemButton
              component={Link}
              to="/leads"
              selected={location.pathname === '/leads'}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 2.5,
                '&.Mui-selected': {
                  backgroundColor: 'action.selected',
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center' }}>
                <LeadsIcon />
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>

          <Tooltip title="Clinic Requests" placement="right">
            <ListItemButton
              component={Link}
              to="/clinic-requests"
              selected={location.pathname === '/clinic-requests'}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 2.5,
                '&.Mui-selected': {
                  backgroundColor: 'action.selected',
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center' }}>
                <ClinicRequestsIcon />
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>

          <Tooltip title="Offers" placement="right">
            <ListItemButton
              component={Link}
              to="/offers"
              selected={location.pathname === '/offers'}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 2.5,
                '&.Mui-selected': {
                  backgroundColor: 'action.selected',
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center' }}>
                <OffersIcon />
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>

          <Tooltip title="Clinics" placement="right">
            <ListItemButton
              component={Link}
              to="/clinics"
              selected={location.pathname === '/clinics'}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 2.5,
                '&.Mui-selected': {
                  backgroundColor: 'action.selected',
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center' }}>
                <ClinicsIcon />
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>

          <Tooltip title="Clinic Logins" placement="right">
            <ListItemButton
              component={Link}
              to="/clinic-logins"
              selected={location.pathname === '/clinic-logins'}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 2.5,
                '&.Mui-selected': {
                  backgroundColor: 'action.selected',
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center' }}>
                <ClinicLoginsIcon />
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>

          <Tooltip title="Images" placement="right">
            <ListItemButton
              component={Link}
              to="/images"
              selected={location.pathname === '/images'}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 2.5,
                '&.Mui-selected': {
                  backgroundColor: 'action.selected',
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center' }}>
                <ImagesIcon />
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>

          <Tooltip title="Services" placement="right">
            <ListItemButton
              component={Link}
              to="/services"
              selected={location.pathname === '/services'}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 2.5,
                '&.Mui-selected': {
                  backgroundColor: 'action.selected',
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center' }}>
                <ServicesIcon />
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>

          {/* Divider */}
          <Box sx={{ my: 1, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} />

          {/* Second Group */}
          <Tooltip title="Treatments" placement="right">
            <ListItemButton
              component={Link}
              to="/treatments"
              selected={location.pathname === '/treatments'}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 2.5,
                '&.Mui-selected': {
                  backgroundColor: 'action.selected',
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center' }}>
                <HealingIcon />
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>

          <Tooltip title="Categories" placement="right">
            <ListItemButton
              component={Link}
              to="/categories"
              selected={location.pathname === '/categories'}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 2.5,
                '&.Mui-selected': {
                  backgroundColor: 'action.selected',
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center' }}>
                <CategoryIcon />
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>

          <Tooltip title="Pages" placement="right">
            <ListItemButton
              component={Link}
              to="/pages"
              selected={location.pathname === '/pages'}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 2.5,
                '&.Mui-selected': {
                  backgroundColor: 'action.selected',
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center' }}>
                <PagesIcon />
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>
        </List>

        {/* Spacer */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Settings and Logout */}
        <List>
          <Tooltip title="Settings" placement="right">
            <ListItemButton
              component={Link}
              to="/settings"
              selected={location.pathname.startsWith('/settings')}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 2.5,
                '&.Mui-selected': {
                  backgroundColor: 'action.selected',
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center' }}>
                <SettingsIcon />
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>

          <Tooltip title="Logout" placement="right">
            <ListItemButton
              onClick={onLogout}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center' }}>
                <Logout />
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>
        </List>
      </Box>
    </Drawer>
  );
}; 