import axios from 'axios';
import { authService } from './authService';

interface StructuredData {
  '@context': string;
  '@type': string;
  name: string;
  description: string;
  url: string;
  image: string;
  procedureType: string;
  bodyLocation: string;
  preparation: string;
  followup: string;
  howPerformed: string;
  medicalSpecialty: string;
  relevantSpecialty: string;
  idealCandidates: string;
  contraindications: string;
  availableService: string;
  provider: string;
  estimatedCost: {
    '@type': string;
    currency: string;
    valueRange: string;
  };
  treatmentTime: string;
  recoveryTime: string;
  possibleComplications: string;
  benefitsOfProcedure: string;
  successRate: string;
  anesthesia: string;
  keywords: string[];
  mainEntityOfPage: string;
  publisher: {
    '@type': string;
    name: string;
    url: string;
    logo: string;
  };
  relatedLink: Array<{
    '@type': string;
    name: string;
    url: string;
  }>;
  mainEntity: {
    '@type': string;
    mainEntity: Array<{
      '@type': string;
      name: string;
      acceptedAnswer: {
        '@type': string;
        text: string;
      };
    }>;
  };
}

export interface Treatment {
  id: string;
  name: string;
  description: string;
  link: string;
  icon: string;
  seo_title: string;
  seo_description: string;
  slug: string;
  structured_data: StructuredData | null;
  created_at: string;
  updated_at: string;
  parent_id: string | null;
}

export interface TreatmentCreateInput {
  name: string;
  description: string;
  link: string;
  icon: string;
  seo_title: string;
  seo_description: string;
  slug: string;
  structured_data: StructuredData | null;
}

export interface TreatmentUpdateInput extends TreatmentCreateInput {
  id?: string;
}

export interface TreatmentListResponse {
  success: boolean;
  data: Treatment[];
  meta: {
    current_page: number;
    per_page: number;
    total: number;
    total_pages: number;
  };
  message?: string;
}

export interface TreatmentResponse {
  success: boolean;
  data: Treatment;
  message?: string;
}

export interface TreatmentListParams {
  page?: number;
  limit?: number;
  search?: string;
}

const BASE_URL = import.meta.env.VITE_API_URL || 'http://localhost:3232';

// Create axios instance with base configuration
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
});

// Add request interceptor for authentication
api.interceptors.request.use(
  (config) => {
    const token = authService.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const treatmentService = {
  getTreatments: async ({ search = '' }: Partial<TreatmentListParams> = {}, signal?: AbortSignal) => {
    try {
      const response = await api.get<TreatmentListResponse>('/api/treatments', {
        params: {
          search,
        },
        signal,
      });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to fetch treatments');
      }

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.name === 'CanceledError' || error.code === 'ERR_CANCELED') {
          throw new Error('Request was cancelled');
        }
        
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to fetch treatment list'
        );
      }
      throw error;
    }
  },

  getTreatment: async (id: string): Promise<Treatment> => {
    try {
      const response = await api.get<TreatmentResponse>(`/api/treatments/${id}`);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to fetch treatment');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to fetch treatment'
        );
      }
      throw error;
    }
  },

  createTreatment: async (data: TreatmentCreateInput): Promise<Treatment> => {
    try {
      // Validate required fields
      if (!data.name || !data.description || !data.link || !data.icon || 
          !data.seo_title || !data.seo_description || !data.slug) {
        throw new Error('Missing required fields');
      }

      // If structured_data is provided, validate its format
      if (data.structured_data) {
        const requiredFields = [
          '@context', '@type', 'name', 'description', 'url', 'image',
          'procedureType', 'bodyLocation', 'preparation', 'followup',
          'howPerformed', 'medicalSpecialty', 'relevantSpecialty',
          'idealCandidates', 'contraindications', 'availableService',
          'provider', 'estimatedCost', 'treatmentTime', 'recoveryTime',
          'possibleComplications', 'benefitsOfProcedure', 'successRate',
          'anesthesia', 'keywords', 'mainEntityOfPage', 'publisher',
          'relatedLink', 'mainEntity'
        ];

        const missingFields = requiredFields.filter(field => !(field in data.structured_data!));
        if (missingFields.length > 0) {
          throw new Error(`Invalid structured data format. Missing fields: ${missingFields.join(', ')}`);
        }

        if (!data.structured_data.estimatedCost || typeof data.structured_data.estimatedCost !== 'object' ||
            !data.structured_data.publisher || typeof data.structured_data.publisher !== 'object' ||
            !Array.isArray(data.structured_data.relatedLink) ||
            !data.structured_data.mainEntity || typeof data.structured_data.mainEntity !== 'object' ||
            !Array.isArray(data.structured_data.mainEntity.mainEntity)) {
          throw new Error('Invalid structured data format: Invalid nested objects or arrays');
        }
      }

      console.log('Sending treatment data to server:', JSON.stringify(data, null, 2));
      const response = await api.post<TreatmentResponse>('/api/treatments', data);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to create treatment');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Server error response:', error.response?.data);
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to create treatment'
        );
      }
      throw error;
    }
  },

  updateTreatment: async (id: string, data: TreatmentUpdateInput): Promise<Treatment> => {
    try {
      const response = await api.put<TreatmentResponse>(`/api/treatments/${id}`, data);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to update treatment');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to update treatment'
        );
      }
      throw error;
    }
  },

  deleteTreatment: async (id: string): Promise<void> => {
    try {
      const response = await api.delete<TreatmentResponse>(`/api/treatments/${id}`);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to delete treatment');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to delete treatment'
        );
      }
      throw error;
    }
  },
}; 