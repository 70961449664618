import axios from 'axios';

const BASE_URL = import.meta.env.VITE_API_URL || 'http://localhost:3232';

// Create axios instance with base configuration
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000, // 15 seconds timeout
});

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface Admin {
  id: number;
  name: string;
  email: string;
  is_deleted: number;
  created_at: string;
  updated_at: string;
}

interface ApiResponse<T> {
  success: boolean;
  data: T;
  message?: string;
}

export interface LoginResponse {
  token: string;
  admin: Admin;
}

export interface ApiError {
  message: string;
  status: number;
}

class AuthService {
  private tokenKey = 'auth_token';
  private adminKey = 'admin_data';

  constructor() {
    // Add request interceptor
    api.interceptors.request.use(
      (config) => {
        const token = this.getToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        // Log request details
        console.log('API Request:', {
          url: config.url,
          method: config.method,
          headers: config.headers,
          data: config.data
        });
        return config;
      },
      (error) => {
        console.error('Request interceptor error:', error);
        return Promise.reject(error);
      }
    );

    // Add response interceptor
    api.interceptors.response.use(
      (response) => {
        // Log successful response
        console.log('API Response:', {
          status: response.status,
          data: response.data
        });
        return response;
      },
      (error) => {
        // Log error response
        console.error('Response interceptor error:', {
          status: error.response?.status,
          data: error.response?.data,
          message: error.message
        });
        
        // Don't auto logout on 401 during login attempt
        if (error.response?.status === 401 && !error.config.url?.includes('/login')) {
          this.logout();
        }
        return Promise.reject(error);
      }
    );
  }

  async login(credentials: LoginCredentials): Promise<LoginResponse> {
    try {
      console.log('Login attempt with:', credentials.email);

      const response = await api.post<ApiResponse<LoginResponse>>('/login', {
        email: credentials.email.trim(),
        password: credentials.password
      });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Login failed');
      }

      const { token, admin } = response.data.data;

      if (!token) {
        throw new Error('No token received from server');
      }

      if (!admin || !admin.id || !admin.email) {
        throw new Error('Invalid admin data received from server');
      }

      // Store authentication data
      this.setToken(token);
      this.setAdmin(admin);

      return { token, admin };
    } catch (error) {
      console.error('Login error details:', error);

      if (axios.isAxiosError(error)) {
        if (!error.response) {
          throw new Error('Cannot connect to the server. Please check if the backend service is running at ' + BASE_URL);
        }

        const status = error.response.status;
        const responseData = error.response.data;

        if (status === 401) {
          throw new Error('Invalid email or password.');
        } else if (status === 404) {
          throw new Error('Login service not found. Please check the API URL.');
        } else if (status === 500) {
          throw new Error('Server error. Please try again later.');
        }

        const errorMessage = responseData?.message || 'Login failed. Please check your credentials.';
        throw new Error(errorMessage);
      }

      if (error instanceof Error) {
        throw error;
      }

      throw new Error('Network error. Please check your connection and try again.');
    }
  }

  async logout(): Promise<void> {
    try {
      // Clear all auth-related data from localStorage
      localStorage.clear();
      
      // Reset axios instance
      api.defaults.headers.common['Authorization'] = '';
      
      // Don't attempt to call logout endpoint
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }

  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  private setToken(token: string): void {
    if (token) {
      localStorage.setItem(this.tokenKey, token);
      // Update axios default headers
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }

  private setAdmin(admin: Admin): void {
    if (admin && typeof admin === 'object' && admin.id && admin.email) {
      localStorage.setItem(this.adminKey, JSON.stringify(admin));
    }
  }

  getAdmin(): Admin | null {
    try {
      const adminData = localStorage.getItem(this.adminKey);
      if (!adminData) {
        return null;
      }
      
      const admin = JSON.parse(adminData);
      if (!admin || typeof admin !== 'object' || !admin.id || !admin.email) {
        return null;
      }
      
      return admin;
    } catch (error) {
      console.error('Error parsing admin data:', error);
      return null;
    }
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    if (!token) {
      return false;
    }

    try {
      const adminData = localStorage.getItem(this.adminKey);
      if (!adminData) {
        return false;
      }

      const admin = JSON.parse(adminData);
      return !!(admin && typeof admin === 'object' && admin.id && admin.email);
    } catch {
      return false;
    }
  }
}

export const authService = new AuthService(); 