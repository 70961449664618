import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Alert,
  Chip,
  Typography,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIcon,
} from '@mui/icons-material';
import { MuiColorInput } from 'mui-color-input';
import { kanbanStagesService, KanbanStage, KanbanStageCreateInput, KanbanStageUpdateInput } from '../../services/kanbanStagesService';

interface FormData {
  name: string;
  description: string;
  settings: {
    color: string;
    icon: string;
  };
  status: 'active' | 'inactive';
}

const initialFormData: FormData = {
  name: '',
  description: '',
  settings: {
    color: '#000000',
    icon: 'check_circle',
  },
  status: 'active',
};

export const KanbanStagesPage = () => {
  const [stages, setStages] = useState<KanbanStage[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formOpen, setFormOpen] = useState(false);
  const [formMode, setFormMode] = useState<'create' | 'edit'>('create');
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [selectedStage, setSelectedStage] = useState<KanbanStage | null>(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const fetchStages = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await kanbanStagesService.getKanbanStages();
      const sortedData = data.sort((a, b) => a.order_position - b.order_position);
      setStages(sortedData);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to fetch kanban stages');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchStages();
  }, [fetchStages]);

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(stages);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedItems = items.map((item, index) => ({
      ...item,
      order_position: index + 1,
    }));

    setStages(updatedItems);

    try {
      await Promise.all(
        updatedItems.map((item) =>
          kanbanStagesService.updateKanbanStagePosition(item.id, item.order_position)
        )
      );
    } catch (err) {
      setError('Failed to update stage positions');
      fetchStages();
    }
  };

  const handleCreate = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const maxPosition = stages.reduce((max, stage) => 
        Math.max(max, stage.order_position), 0);

      const createData: KanbanStageCreateInput = {
        ...formData,
        order_position: maxPosition + 1,
      };
      
      await kanbanStagesService.createKanbanStage(createData);
      setFormOpen(false);
      fetchStages();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create kanban stage');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    if (!selectedStage) return;

    try {
      setLoading(true);
      setError(null);
      const updateData: KanbanStageUpdateInput = {
        name: formData.name,
        description: formData.description,
        settings: formData.settings,
        status: formData.status,
      };
      await kanbanStagesService.updateKanbanStage(selectedStage.id, updateData);
      setFormOpen(false);
      setSelectedStage(null);
      fetchStages();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update kanban stage');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!selectedStage) return;

    try {
      setLoading(true);
      setError(null);
      await kanbanStagesService.deleteKanbanStage(selectedStage.id);
      setDeleteConfirmOpen(false);
      setSelectedStage(null);
      fetchStages();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to delete kanban stage');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (stage: KanbanStage) => {
    setSelectedStage(stage);
    setFormData({
      name: stage.name,
      description: stage.description,
      settings: stage.settings,
      status: stage.status,
    });
    setFormMode('edit');
    setFormOpen(true);
  };

  const handleFormClose = () => {
    setFormOpen(false);
    setSelectedStage(null);
    setFormMode('create');
    setFormData(initialFormData);
  };

  return (
    <Box>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Kanban Stages</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setFormMode('create');
            setFormOpen(true);
          }}
        >
          Add Stage
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <DragDropContext onDragEnd={handleDragEnd}>
        <Paper sx={{ maxHeight: 'calc(100vh - 250px)', overflow: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width={50}></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Color</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <Droppable droppableId="stages">
              {(provided) => (
                <TableBody
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {stages.map((stage, index) => (
                    <Draggable
                      key={stage.id}
                      draggableId={String(stage.id)}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          sx={{
                            bgcolor: snapshot.isDragging ? 'action.hover' : 'inherit',
                          }}
                        >
                          <TableCell {...provided.dragHandleProps}>
                            <DragIcon />
                          </TableCell>
                          <TableCell>{stage.name}</TableCell>
                          <TableCell sx={{ color: 'text.secondary' }}>{stage.description}</TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                width: 24,
                                height: 24,
                                borderRadius: '50%',
                                bgcolor: stage.settings.color,
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Chip
                              label={stage.status}
                              color={stage.status === 'active' ? 'success' : 'default'}
                              size="small"
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              size="small"
                              onClick={() => handleEdit(stage)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => {
                                setSelectedStage(stage);
                                setDeleteConfirmOpen(true);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </Paper>
      </DragDropContext>

      {/* Create/Edit Form Dialog */}
      <Dialog open={formOpen} onClose={handleFormClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          {formMode === 'create' ? 'Create Kanban Stage' : 'Edit Kanban Stage'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            <TextField
              label="Name"
              value={formData.name}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, name: e.target.value }))
              }
              fullWidth
              required
            />
            <TextField
              label="Description"
              value={formData.description}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, description: e.target.value }))
              }
              fullWidth
              multiline
              rows={2}
            />
            <MuiColorInput
              label="Color"
              value={formData.settings.color}
              onChange={(newColor: string) =>
                setFormData((prev) => ({
                  ...prev,
                  settings: { ...prev.settings, color: newColor },
                }))
              }
              fullWidth
            />
            <TextField
              select
              label="Status"
              value={formData.status}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  status: e.target.value as 'active' | 'inactive',
                }))
              }
              fullWidth
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFormClose}>Cancel</Button>
          <Button
            onClick={formMode === 'create' ? handleCreate : handleUpdate}
            variant="contained"
            disabled={loading}
          >
            {formMode === 'create' ? 'Create' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>Delete Kanban Stage</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this kanban stage?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}; 