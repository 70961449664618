import { api } from './clinicService';

export interface Video {
  id: number;
  clinic_id: number;
  title: string;
  description: string;
  file_path: string;
  hls_path: string;
  thumbnail: string;
  duration: number;
  qualities: string[];
  status: 'processing' | 'ready' | 'failed';
  view_count: number;
  created_at: string;
  updated_at: string;
}

export interface VideoResponse {
  success: boolean;
  data: Video | null;
}

export interface VideoUploadInput {
  clinic_id: number | string;
  file: File;
  title: string;
  description: string;
}

export interface VideoUploadProgress {
  progress: number;
  status: 'uploading' | 'processing' | 'ready' | 'failed';
}

export const videoService = {
  getClinicVideo: async (clinicId: number | string): Promise<VideoResponse> => {
    try {
      const response = await api.get<VideoResponse>(`/api/videos?clinic_id=${clinicId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching clinic video:', error);
      throw error;
    }
  },

  uploadVideo: async (
    data: VideoUploadInput, 
    onProgress?: (progress: VideoUploadProgress) => void
  ): Promise<VideoResponse> => {
    try {
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('title', data.title);
      formData.append('description', data.description);

      const response = await api.post<VideoResponse>(`/api/videos?clinic_id=${data.clinic_id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 300000, // 5 minutes timeout for video uploads
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            onProgress?.({
              progress,
              status: 'uploading'
            });
          }
        }
      });

      // Start checking processing status
      if (response.data.data) {
        onProgress?.({ progress: 100, status: 'processing' });
        await videoService.waitForProcessing(data.clinic_id);
      }

      return response.data;
    } catch (error) {
      console.error('Error uploading video:', error);
      onProgress?.({ progress: 0, status: 'failed' });
      throw error;
    }
  },

  waitForProcessing: async (clinicId: number | string): Promise<void> => {
    let attempts = 0;
    const maxAttempts = 30; // 5 minutes maximum (10 seconds * 30)

    while (attempts < maxAttempts) {
      try {
        const response = await videoService.getClinicVideo(clinicId);
        if (!response.data) break;

        if (response.data.status === 'ready') {
          break;
        } else if (response.data.status === 'failed') {
          throw new Error('Video processing failed');
        }

        // Wait 10 seconds before next check
        await new Promise(resolve => setTimeout(resolve, 10000));
        attempts++;
      } catch (error) {
        console.error('Error checking video status:', error);
        throw error;
      }
    }

    if (attempts >= maxAttempts) {
      throw new Error('Video processing timeout');
    }
  },

  deleteVideo: async (clinicId: number | string): Promise<void> => {
    try {
      await api.delete(`/api/videos?clinic_id=${clinicId}`);
    } catch (error) {
      console.error('Error deleting video:', error);
      throw error;
    }
  }
}; 