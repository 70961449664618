import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  Breadcrumbs,
  Link,
  CircularProgress,
  Alert,
  IconButton,
  Button,
} from '@mui/material';
import { ArrowBack as ArrowBackIcon, Edit as EditIcon } from '@mui/icons-material';
import { treatmentService, Treatment } from '../services/treatmentService';

export const TreatmentDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [treatment, setTreatment] = useState<Treatment | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTreatment = async () => {
      if (!id) return;
      
      try {
        setLoading(true);
        setError(null);
        const response = await treatmentService.getTreatment(id);
        setTreatment(response);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to fetch treatment details';
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchTreatment();
  }, [id]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      </Container>
    );
  }

  if (!treatment) {
    return (
      <Container>
        <Alert severity="info" sx={{ mt: 2 }}>
          Treatment not found
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth={false} sx={{ p: 3 }}>
      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <IconButton onClick={() => navigate('/treatments')} sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Breadcrumbs aria-label="breadcrumb">
            <Link 
              component="button"
              variant="body1"
              onClick={() => navigate('/treatments')}
            >
              Treatments
            </Link>
            <Typography color="text.primary">{treatment.name}</Typography>
          </Breadcrumbs>
        </Box>
      </Box>

      <Paper sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 3 }}>
          <Typography variant="h4" component="h1">
            {treatment.name}
          </Typography>
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            onClick={() => navigate(`/treatments/${treatment.id}/edit`)}
          >
            Edit Treatment
          </Button>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Description
            </Typography>
            <Typography paragraph>
              {treatment.description}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              SEO Information
            </Typography>
            <Box sx={{ bgcolor: 'background.default', p: 2, borderRadius: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                Title
              </Typography>
              <Typography paragraph>
                {treatment.seo_title}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                Description
              </Typography>
              <Typography>
                {treatment.seo_description}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Additional Information
            </Typography>
            <Box sx={{ bgcolor: 'background.default', p: 2, borderRadius: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                Link
              </Typography>
              <Typography paragraph>
                {treatment.link}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                Icon
              </Typography>
              <Typography>
                {treatment.icon}
              </Typography>
            </Box>
          </Grid>

          {treatment.structured_data && (
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Structured Data
              </Typography>
              <Box sx={{ bgcolor: 'background.default', p: 2, borderRadius: 1 }}>
                <pre style={{ margin: 0, whiteSpace: 'pre-wrap' }}>
                  {JSON.stringify(treatment.structured_data, null, 2)}
                </pre>
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Container>
  );
}; 