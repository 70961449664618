import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Container,
  Typography,
  Alert,
  Box,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  CircularProgress,
  Chip,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Link as LinkIcon,
  Schedule as ScheduleIcon,
} from '@mui/icons-material';
import { Treatment, treatmentService, TreatmentCreateInput, TreatmentUpdateInput } from '../services/treatmentService';
import { TreatmentForm } from '../components/TreatmentForm';

export const TreatmentsPage: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(true);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [treatments, setTreatments] = useState<Treatment[]>([]);
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [selectedTreatment, setSelectedTreatment] = useState<Treatment | undefined>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [treatmentToDelete, setTreatmentToDelete] = useState<Treatment | undefined>();

  useEffect(() => {
    const controller = new AbortController();
    let isMounted = true;

    const fetchData = async () => {
      try {
        setLoading(true);
        setError(undefined);
        const response = await treatmentService.getTreatments({}, controller.signal);
        if (response.success && Array.isArray(response.data) && isMounted) {
          setTreatments(response.data);
        } else if (isMounted) {
          setTreatments([]);
        }
      } catch (err) {
        if (err instanceof Error && (err.name === 'CanceledError' || err.message === 'Request was cancelled')) {
          return;
        }
        if (isMounted) {
          const errorMessage = err instanceof Error ? err.message : 'Failed to fetch treatments';
          setError(errorMessage);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      controller.abort();
      isMounted = false;
    };
  }, []);

  const handleCreateTreatment = async (data: TreatmentCreateInput) => {
    try {
      setFormLoading(true);
      await treatmentService.createTreatment(data);
      setFormOpen(false);
      setTreatments(prevTreatments => [...prevTreatments, data as Treatment]);
      enqueueSnackbar('Treatment created successfully', { variant: 'success' });
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to create treatment';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setFormLoading(false);
    }
  };

  const handleUpdateTreatment = async (data: TreatmentUpdateInput) => {
    if (!selectedTreatment) return;

    try {
      setFormLoading(true);
      await treatmentService.updateTreatment(selectedTreatment.id.toString(), data);
      setFormOpen(false);
      setSelectedTreatment(undefined);
      setTreatments(prevTreatments => 
        prevTreatments.map(treatment => 
          treatment.id === selectedTreatment.id ? { ...treatment, ...data } : treatment
        )
      );
      enqueueSnackbar('Treatment updated successfully', { variant: 'success' });
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to update treatment';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setFormLoading(false);
    }
  };

  const handleFormSubmit = async (data: TreatmentCreateInput | TreatmentUpdateInput) => {
    if (selectedTreatment) {
      await handleUpdateTreatment(data);
    } else {
      await handleCreateTreatment(data as TreatmentCreateInput);
    }
  };

  const handleDeleteTreatment = async () => {
    if (!treatmentToDelete) return;

    try {
      await treatmentService.deleteTreatment(treatmentToDelete.id.toString());
      setDeleteDialogOpen(false);
      setTreatmentToDelete(undefined);
      setTreatments(prevTreatments => 
        prevTreatments.filter(treatment => treatment.id !== treatmentToDelete.id)
      );
      enqueueSnackbar('Treatment deleted successfully', { variant: 'success' });
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to delete treatment';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  };

  const getParentName = (parentId: string | null) => {
    if (!parentId) return null;
    const parent = treatments.find(t => t.id === parentId);
    return parent?.name;
  };

  const handleFormClose = () => {
    setFormOpen(false);
    setSelectedTreatment(undefined);
    setFormLoading(false);
  };

  return (
    <Container maxWidth={false} sx={{ p: 3 }}>
      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h4" component="h1">
            Treatments
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setSelectedTreatment(undefined);
              setFormOpen(true);
            }}
          >
            Create Treatment
          </Button>
        </Box>
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : treatments.length > 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {treatments.map((treatment) => (
            <Paper
              key={`treatment-paper-${treatment.id}`}
              sx={{
                p: 3,
                transition: 'all 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: 2,
                },
              }}
            >
              <Box 
                key={`treatment-content-${treatment.id}`}
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}
              >
                <Box 
                  key={`treatment-info-${treatment.id}`}
                  sx={{ flex: 1 }}
                >
                  <Box 
                    key={`treatment-header-${treatment.id}`}
                    sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}
                  >
                    <Typography 
                      key={`treatment-title-${treatment.id}`}
                      variant="h6" 
                      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    >
                      {treatment.name}
                    </Typography>
                    {treatment.parent_id && (
                      <Chip
                        key={`treatment-parent-${treatment.parent_id}`}
                        label={`Sub-treatment of ${getParentName(treatment.parent_id)}`}
                        size="small"
                        color="info"
                        variant="outlined"
                      />
                    )}
                  </Box>
                  <Typography 
                    key={`treatment-description-${treatment.id}`}
                    variant="body2" 
                    color="text.secondary" 
                    sx={{ mb: 2 }}
                  >
                    {treatment.description}
                  </Typography>
                  <Box 
                    key={`treatment-chips-${treatment.id}`}
                    sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}
                  >
                    <Chip
                      key={`treatment-link-${treatment.id}`}
                      icon={<LinkIcon />}
                      label={treatment.link}
                      size="small"
                      variant="outlined"
                    />
                    <Chip
                      key={`treatment-date-${treatment.id}`}
                      icon={<ScheduleIcon />}
                      label={new Date(treatment.updated_at).toLocaleDateString()}
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                </Box>
                <Box 
                  key={`treatment-actions-${treatment.id}`}
                  sx={{ display: 'flex', gap: 1 }}
                >
                  <Tooltip key={`edit-tooltip-${treatment.id}`} title="Edit">
                    <span>
                      <IconButton
                        key={`edit-${treatment.id}`}
                        size="small"
                        onClick={() => {
                          setSelectedTreatment(treatment);
                          setFormOpen(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip key={`delete-tooltip-${treatment.id}`} title="Delete">
                    <span>
                      <IconButton
                        key={`delete-${treatment.id}`}
                        size="small"
                        onClick={() => {
                          setTreatmentToDelete(treatment);
                          setDeleteDialogOpen(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              </Box>
            </Paper>
          ))}
        </Box>
      ) : (
        <Paper sx={{ p: 4, textAlign: 'center' }}>
          <Typography color="text.secondary">No treatments available.</Typography>
        </Paper>
      )}

      <TreatmentForm
        open={formOpen}
        onClose={handleFormClose}
        onSubmit={handleFormSubmit}
        initialData={selectedTreatment}
        loading={formLoading}
        mode={selectedTreatment ? 'edit' : 'create'}
      />

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete Treatment</DialogTitle>
        <DialogContent>
          Are you sure you want to delete {treatmentToDelete?.name}?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteTreatment} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}; 