import { useState, useEffect, useRef } from 'react';
import {
  Box,
  Paper,
  TextField,
  Button,
  Alert,
  Typography,
  Grid,
  Divider,
  CircularProgress,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Upload as UploadIcon,
  DragIndicator as DragIcon,
} from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import {
  homepageSettingsService,
  HomepageSettings,
  HomeButton,
} from '../../services/homepageSettingsService';

const initialSettings: HomepageSettings = {
  hero_title: '',
  hero_subtitle: '',
  hero_cta: {
    text: '',
    url: '',
  },
  buttons: [],
};

const emptyButton: HomeButton = {
  text: '',
  url: '',
  image_url: '',
};

export const HomepageSettingsPage = () => {
  const [settings, setSettings] = useState<HomepageSettings>(initialSettings);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [buttonToDelete, setButtonToDelete] = useState<number | null>(null);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await homepageSettingsService.getHomepageSettings();
      setSettings(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to fetch homepage settings');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);
      setSuccess(null);
      await homepageSettingsService.updateHomepageSettings(settings);
      setSuccess('Homepage settings updated successfully');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update homepage settings');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field: keyof HomepageSettings, value: string) => {
    setSettings((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleCtaChange = (field: keyof typeof settings.hero_cta, value: string) => {
    setSettings((prev) => ({
      ...prev,
      hero_cta: {
        ...prev.hero_cta,
        [field]: value,
      },
    }));
  };

  const handleButtonChange = (index: number, field: keyof HomeButton, value: string) => {
    setSettings((prev) => ({
      ...prev,
      buttons: prev.buttons.map((button, i) =>
        i === index ? { ...button, [field]: value } : button
      ),
    }));
  };

  const handleAddButton = () => {
    setSettings((prev) => ({
      ...prev,
      buttons: [...prev.buttons, { ...emptyButton }],
    }));
  };

  const handleDeleteButton = (index: number) => {
    setButtonToDelete(index);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    if (buttonToDelete !== null) {
      const newButtons = settings.buttons.filter((_, i) => i !== buttonToDelete);
      setSettings(prev => ({
        ...prev,
        buttons: newButtons
      }));
    }
    setDeleteDialogOpen(false);
    setButtonToDelete(null);
  };

  const cancelDelete = () => {
    setDeleteDialogOpen(false);
    setButtonToDelete(null);
  };

  const handleButtonImageUpload = async (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setLoading(true);
      setError(null);

      const imagePath = await homepageSettingsService.uploadButtonImage(file);
      
      handleButtonChange(index, 'image_url', imagePath);
      setSuccess('Button image uploaded successfully');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to upload button image');
    } finally {
      setLoading(false);
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(settings.buttons);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSettings((prev) => ({
      ...prev,
      buttons: items,
    }));
  };

  if (loading && !settings.hero_title) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Homepage Settings
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}

      <Grid container spacing={3}>
        {/* Settings Form */}
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 3 }}>
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <Typography variant="subtitle1" color="primary">Hero Section</Typography>
                <TextField
                  label="Hero Title"
                  value={settings.hero_title}
                  onChange={(e) => handleChange('hero_title', e.target.value)}
                  fullWidth
                  required
                />
                <TextField
                  label="Hero Subtitle"
                  value={settings.hero_subtitle}
                  onChange={(e) => handleChange('hero_subtitle', e.target.value)}
                  fullWidth
                  multiline
                  rows={2}
                  required
                />
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    label="CTA Button Text"
                    value={settings.hero_cta.text}
                    onChange={(e) => handleCtaChange('text', e.target.value)}
                    fullWidth
                    required
                  />
                  <TextField
                    label="CTA Button URL"
                    value={settings.hero_cta.url}
                    onChange={(e) => handleCtaChange('url', e.target.value)}
                    fullWidth
                    required
                  />
                </Box>

                <Divider sx={{ my: 2 }} />

                <Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="subtitle1" color="primary">
                      Buttons
                    </Typography>
                    <Button
                      startIcon={<AddIcon />}
                      onClick={handleAddButton}
                      variant="outlined"
                      size="small"
                    >
                      Add Button
                    </Button>
                  </Box>

                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="buttons">
                      {(provided) => (
                        <Box
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                        >
                          {settings.buttons.map((button, index) => (
                            <Draggable
                              key={index}
                              draggableId={`button-${index}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <Paper
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  elevation={snapshot.isDragging ? 3 : 1}
                                  sx={{
                                    p: 2,
                                    bgcolor: snapshot.isDragging ? 'action.hover' : 'background.paper',
                                  }}
                                >
                                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                    <Box {...provided.dragHandleProps}>
                                      <DragIcon color="action" />
                                    </Box>
                                    
                                    {button.image_url ? (
                                      <Box sx={{ width: 40, height: 40, position: 'relative' }}>
                                        <img
                                          src={button.image_url}
                                          alt={button.text}
                                          style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 4 }}
                                        />
                                        <IconButton
                                          size="small"
                                          sx={{ position: 'absolute', top: -8, right: -8 }}
                                          onClick={() => handleButtonChange(index, 'image_url', '')}
                                        >
                                          <DeleteIcon fontSize="small" />
                                        </IconButton>
                                      </Box>
                                    ) : (
                                      <Button
                                        variant="outlined"
                                        component="label"
                                        size="small"
                                        sx={{ minWidth: 40, height: 40, p: 0 }}
                                      >
                                        <UploadIcon />
                                        <input
                                          ref={(el) => fileInputRefs.current[index] = el}
                                          type="file"
                                          hidden
                                          accept="image/*"
                                          onChange={(e) => handleButtonImageUpload(index, e)}
                                        />
                                      </Button>
                                    )}

                                    <TextField
                                      size="small"
                                      placeholder="Button Text"
                                      value={button.text}
                                      onChange={(e) => handleButtonChange(index, 'text', e.target.value)}
                                      sx={{ flex: 1 }}
                                    />
                                    <TextField
                                      size="small"
                                      placeholder="URL"
                                      value={button.url}
                                      onChange={(e) => handleButtonChange(index, 'url', e.target.value)}
                                      sx={{ flex: 1 }}
                                    />
                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={() => handleDeleteButton(index)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Box>
                                </Paper>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                  >
                    {loading ? 'Saving...' : 'Save Changes'}
                  </Button>
                </Box>
              </Box>
            </form>
          </Paper>
        </Grid>

        {/* Preview */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, position: 'sticky', top: 20 }}>
            <Typography variant="subtitle1" gutterBottom color="primary">
              Preview
            </Typography>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  {settings.hero_title || 'Hero Title'}
                </Typography>
                <Typography variant="body1" color="text.secondary" paragraph>
                  {settings.hero_subtitle || 'Hero Subtitle'}
                </Typography>
                <Button variant="contained" sx={{ mb: 2 }}>
                  {settings.hero_cta.text || 'CTA Button'}
                </Button>

                {settings.buttons.length > 0 && (
                  <Box sx={{ mt: 3 }}>
                    <Divider sx={{ mb: 2 }} />
                    <Grid container spacing={2}>
                      {settings.buttons.map((button, index) => (
                        <Grid item xs={12} key={index}>
                          <Card variant="outlined">
                            <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                              {button.image_url && (
                                <Box sx={{ width: 60 }}>
                                  <img
                                    src={button.image_url}
                                    alt={button.text}
                                    style={{ width: '100%', height: 'auto' }}
                                  />
                                </Box>
                              )}
                              <Box>
                                <Button variant="outlined" size="small">
                                  {button.text || `Button ${index + 1}`}
                                </Button>
                                <Typography variant="caption" display="block" color="text.secondary">
                                  {button.url || '/button-url'}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={cancelDelete}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">
          Delete Button
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete this button?
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete}>Cancel</Button>
          <Button onClick={confirmDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}; 