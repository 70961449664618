import { ReactNode } from 'react';
import { LoadScript } from '@react-google-maps/api';

interface GoogleMapsProviderProps {
  children: ReactNode;
}

const libraries: ("places" | "geometry" | "drawing" | "visualization")[] = ["places"];

export const GoogleMapsProvider = ({ children }: GoogleMapsProviderProps) => {
  return (
    <LoadScript
      googleMapsApiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
      libraries={libraries}
    >
      {children}
    </LoadScript>
  );
}; 