import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { Sidebar } from './Sidebar';
import { authService } from '../services/authService';

const DRAWER_WIDTH = 58;

const Layout: React.FC = () => {
  const navigate = useNavigate();
  
  const handleLogout = async () => {
    try {
      await authService.logout();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Logout error:', error);
      navigate('/login', { replace: true });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        width: '100vw',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Sidebar onLogout={handleLogout} />
      <Box
        component="main"
        sx={{
          position: 'absolute',
          left: `${DRAWER_WIDTH}px`,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: 'grey.100',
          overflow: 'auto',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout; 