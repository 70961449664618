import { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  MenuItem,
  Grid,
  Card,
  CardMedia,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  Pagination,
  Checkbox,
  Snackbar,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Upload as UploadIcon,
} from '@mui/icons-material';
import { imagesService, Image, ImageCreateInput, ImageUpdateInput } from '../services/imagesService';

interface ImageFormProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: ImageCreateInput | ImageUpdateInput) => Promise<void>;
  initialData?: Image;
  loading?: boolean;
  mode: 'create' | 'edit';
}

const ImageForm = ({
  open,
  onClose,
  onSubmit,
  initialData,
  loading = false,
  mode,
}: ImageFormProps) => {
  const [formData, setFormData] = useState<ImageCreateInput>({
    file: null as unknown as File,
    clinic_id: 0,
    view: 'GALLERY',
    rank: 0,
  });
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (initialData) {
      setFormData({
        file: null as unknown as File,
        clinic_id: initialData.clinic_id,
        view: initialData.view,
        rank: initialData.rank,
      });
      setPreviewUrl(initialData.url);
    } else {
      setFormData({
        file: null as unknown as File,
        clinic_id: 0,
        view: 'GALLERY',
        rank: 0,
      });
      setPreviewUrl('');
    }
  }, [initialData]);

  const handleChange = (field: keyof ImageCreateInput) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setFormData(prev => ({ ...prev, file }));
      setPreviewUrl(URL.createObjectURL(file));
      setError(null);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (mode === 'create' && !formData.file) {
      setError('Please select an image file');
      return;
    }
    try {
      await onSubmit(formData);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to submit form');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle component="div">
          <Typography variant="h6">
            {mode === 'create' ? 'Add New Image' : 'Edit Image'}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
                mb: 2
              }}>
                {previewUrl && (
                  <Box sx={{ 
                    width: '100%',
                    maxHeight: 300,
                    overflow: 'hidden',
                    borderRadius: 1,
                    bgcolor: 'grey.100',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <img 
                      src={previewUrl}
                      alt="Preview"
                      style={{
                        maxWidth: '100%',
                        maxHeight: 300,
                        objectFit: 'contain'
                      }}
                    />
                  </Box>
                )}
                <Box sx={{ display: 'flex', gap: 1, width: '100%' }}>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="upload-image"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="upload-image" style={{ width: '100%' }}>
                    <Button
                      component="span"
                      variant="outlined"
                      startIcon={<UploadIcon />}
                      fullWidth
                    >
                      {mode === 'create' ? 'Select Image' : 'Change Image'}
                    </Button>
                  </label>
                </Box>
                {error && (
                  <Typography color="error" variant="body2">
                    {error}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="number"
                label="Clinic ID"
                value={formData.clinic_id}
                onChange={handleChange('clinic_id')}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <InputLabel>View Type</InputLabel>
                <Select
                  value={formData.view}
                  label="View Type"
                  onChange={(event) => handleChange('view')(event as any)}
                >
                  <MenuItem value="GALLERY">Gallery</MenuItem>
                  <MenuItem value="BEFORE_AFTER">Before/After</MenuItem>
                  <MenuItem value="PROFILE">Profile</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="number"
                label="Rank"
                value={formData.rank}
                onChange={handleChange('rank')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button 
            type="submit" 
            variant="contained" 
            disabled={loading || (mode === 'create' && !formData.file)}
          >
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export const ImagesPage = () => {
  const [images, setImages] = useState<Image[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [totalPages, setTotalPages] = useState(1);
  const [fetchParams, setFetchParams] = useState({
    page: 1,
    limit: 20,
    search: '',
    view: '' as 'GALLERY' | 'BEFORE_AFTER' | 'PROFILE' | '',
  });
  const [formOpen, setFormOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Image | undefined>();
  const [formLoading, setFormLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [imageToDelete, setImageToDelete] = useState<Image | undefined>();
  const [selectedImages, setSelectedImages] = useState<Set<number>>(new Set());
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState<Image | null>(null);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    const abortController = new AbortController();
    let isMounted = true;

    const fetchImages = async () => {
      try {
        setLoading(true);
        setError(null);
        const { images: fetchedImages, total } = await imagesService.getImages({
          page: fetchParams.page,
          limit: fetchParams.limit,
          search: fetchParams.search,
          view: fetchParams.view || undefined,
        }, abortController.signal);

        if (isMounted) {
          setImages(fetchedImages);
          setTotalPages(Math.ceil(total / fetchParams.limit));
        }
      } catch (err) {
        if (isMounted && err instanceof Error && err.message !== 'Request was cancelled') {
          setError(err.message);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchImages();

    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [fetchParams]);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setFetchParams(prev => ({ ...prev, page: value }));
  };

  const handleDeleteImage = async () => {
    if (!imageToDelete) return;

    try {
      setLoading(true);
      await imagesService.deleteImage(imageToDelete.id);
      setDeleteDialogOpen(false);
      setImageToDelete(undefined);
      setFetchParams(prev => ({ ...prev })); // Trigger refetch
      setSnackbar({
        open: true,
        message: 'Image successfully deleted',
        severity: 'success'
      });
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to delete image';
      setError(errorMessage);
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCreateImage = async (data: ImageCreateInput) => {
    try {
      setFormLoading(true);
      await imagesService.createImage(data);
      setFormOpen(false);
      setFetchParams(prev => ({ ...prev })); // Trigger refetch
      setSnackbar({
        open: true,
        message: 'Image successfully created',
        severity: 'success'
      });
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to create image';
      setError(errorMessage);
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error'
      });
    } finally {
      setFormLoading(false);
    }
  };

  const handleUpdateImage = async (data: ImageUpdateInput) => {
    if (!selectedImage) return;

    try {
      setFormLoading(true);
      await imagesService.updateImage(selectedImage.id, data);
      setFormOpen(false);
      setSelectedImage(undefined);
      setFetchParams(prev => ({ ...prev })); // Trigger refetch
      setSnackbar({
        open: true,
        message: 'Image successfully updated',
        severity: 'success'
      });
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to update image';
      setError(errorMessage);
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error'
      });
    } finally {
      setFormLoading(false);
    }
  };

  const handleFormSubmit = async (data: ImageCreateInput | ImageUpdateInput) => {
    if (selectedImage) {
      await handleUpdateImage(data as ImageUpdateInput);
    } else {
      await handleCreateImage(data as ImageCreateInput);
    }
  };

  const handleToggleSelection = (imageId: number) => {
    setSelectedImages(prev => {
      const newSelection = new Set(prev);
      if (newSelection.has(imageId)) {
        newSelection.delete(imageId);
      } else {
        newSelection.add(imageId);
      }
      return newSelection;
    });
  };

  const handleSelectAll = () => {
    if (selectedImages.size === images.length) {
      setSelectedImages(new Set());
    } else {
      setSelectedImages(new Set(images.map(img => img.id)));
    }
  };

  const handleDeleteSelected = async () => {
    try {
      setLoading(true);
      const promises = Array.from(selectedImages).map(id => imagesService.deleteImage(id));
      await Promise.all(promises);
      setSelectedImages(new Set());
      setIsSelectionMode(false);
      setFetchParams(prev => ({ ...prev })); // Trigger refetch
      setSnackbar({
        open: true,
        message: `Successfully deleted ${selectedImages.size} images`,
        severity: 'success'
      });
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to delete selected images';
      setError(errorMessage);
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePreviewOpen = (image: Image) => {
    setPreviewImage(image);
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setPreviewImage(null);
  };

  return (
    <Container maxWidth={false} sx={{ p: 3 }}>
      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h4" component="h1">
            Images
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {isSelectionMode ? (
              <>
                <Button
                  variant="outlined"
                  onClick={handleSelectAll}
                  disabled={loading}
                >
                  {selectedImages.size === images.length ? 'Deselect All' : 'Select All'}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setDeleteDialogOpen(true)}
                  disabled={loading || selectedImages.size === 0}
                >
                  Delete Selected ({selectedImages.size})
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setIsSelectionMode(false);
                    setSelectedImages(new Set());
                  }}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  onClick={() => setIsSelectionMode(true)}
                >
                  Select Multiple
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setSelectedImage(undefined);
                    setFormOpen(true);
                  }}
                >
                  Add Image
                </Button>
              </>
            )}
          </Box>
        </Box>

        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <TextField
            label="Search"
            value={fetchParams.search}
            onChange={(e) => setFetchParams(prev => ({ ...prev, search: e.target.value }))}
            size="small"
          />
          <FormControl sx={{ minWidth: 200 }} size="small">
            <InputLabel>View Type</InputLabel>
            <Select
              value={fetchParams.view}
              label="View Type"
              onChange={(e) => setFetchParams(prev => ({ ...prev, view: e.target.value as typeof fetchParams.view }))}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="GALLERY">Gallery</MenuItem>
              <MenuItem value="BEFORE_AFTER">Before/After</MenuItem>
              <MenuItem value="PROFILE">Profile</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Box>

      <Grid container spacing={2}>
        {images.map((image) => (
          <Grid item xs={12} sm={6} md={3} lg={2} key={image.id}>
            <Card 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                position: 'relative',
                transition: 'transform 0.2s, box-shadow 0.2s',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 3,
                },
                ...(isSelectionMode && {
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                }),
              }}
              onClick={isSelectionMode ? () => handleToggleSelection(image.id) : () => handlePreviewOpen(image)}
            >
              {isSelectionMode && (
                <Checkbox
                  checked={selectedImages.has(image.id)}
                  sx={{
                    position: 'absolute',
                    top: 8,
                    left: 8,
                    zIndex: 2,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    boxShadow: 1,
                  }}
                />
              )}
              <Box sx={{ position: 'relative' }}>
                <CardMedia
                  component="img"
                  height="160"
                  image={image.url}
                  alt={`Image ${image.id}`}
                  sx={{ 
                    objectFit: 'cover',
                    bgcolor: 'grey.100',
                  }}
                />
              </Box>
              <CardContent sx={{ 
                flexGrow: 1, 
                py: 1,
                px: 1.5,
                '&:last-child': { pb: 1 },
              }}>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center',
                  mb: 0.5 
                }}>
                  <Typography 
                    variant="body2" 
                    color="text.primary" 
                    sx={{ 
                      fontWeight: 500,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5,
                    }}
                  >
                    #{image.id} - Clinic {image.clinic_id}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 0.5 }}>
                    <Tooltip title="Edit">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedImage(image);
                          setFormOpen(true);
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setImageToDelete(image);
                          setDeleteDialogOpen(true);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{
                    display: 'inline-block',
                    bgcolor: 'action.selected',
                    px: 1,
                    py: 0.25,
                    borderRadius: 1,
                    fontSize: '0.75rem',
                  }}
                >
                  {image.view}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {!loading && !error && images.length > 0 && (
        <Box sx={{ mt: 4, mb: 2, display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={totalPages}
            page={fetchParams.page}
            onChange={handlePageChange}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
          />
        </Box>
      )}

      {!loading && !error && images.length === 0 && (
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography variant="body1" color="text.secondary">
            No images found
          </Typography>
        </Box>
      )}

      <Dialog 
        open={previewOpen} 
        onClose={handlePreviewClose}
        maxWidth="md"
        fullWidth
        disablePortal={false}
        keepMounted={false}
        aria-labelledby="preview-dialog-title"
        aria-describedby="preview-dialog-description"
      >
        <DialogTitle 
          id="preview-dialog-title"
          component="div"
          sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center' 
          }}
        >
          <Typography variant="h6">
            Image Preview
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              #{previewImage?.id} - Clinic {previewImage?.clinic_id}
            </Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={() => window.open(previewImage?.url, '_blank')}
            >
              Open Original
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent 
          id="preview-dialog-description"
          dividers 
          sx={{ p: 0, display: 'flex', justifyContent: 'center', bgcolor: 'grey.100' }}
        >
          {previewImage && (
            <img
              src={previewImage.url}
              alt={`Preview ${previewImage.id}`}
              style={{
                maxWidth: '100%',
                maxHeight: 'calc(90vh - 120px)',
                objectFit: 'contain',
              }}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', px: 2 }}>
          <Typography variant="body2" color="text.secondary">
            View Type: {previewImage?.view}
          </Typography>
          <Button onClick={handlePreviewClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <ImageForm
        open={formOpen}
        onClose={() => {
          setFormOpen(false);
          setSelectedImage(undefined);
        }}
        onSubmit={handleFormSubmit}
        initialData={selectedImage}
        loading={formLoading}
        mode={selectedImage ? 'edit' : 'create'}
      />

      <Dialog 
        open={deleteDialogOpen} 
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
        disablePortal={false}
        keepMounted={false}
      >
        <DialogTitle 
          id="delete-dialog-title"
          component="div"
        >
          <Typography variant="h6">
            Delete {selectedImages.size > 0 ? 'Images' : 'Image'}
          </Typography>
        </DialogTitle>
        <DialogContent id="delete-dialog-description">
          {selectedImages.size > 0 ? (
            `Are you sure you want to delete ${selectedImages.size} selected images?`
          ) : (
            'Are you sure you want to delete this image?'
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={selectedImages.size > 0 ? handleDeleteSelected : handleDeleteImage}
            color="error"
            disabled={loading}
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}; 