import { useEffect, useState, useCallback } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  IconButton,
  Tooltip,
  TextField,
  Alert,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { DataTable, Column } from '../components/DataTable';
import { pagesService, Page } from '../services/pagesService';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

const columns: Column[] = [
  { id: 'id', label: 'ID', minWidth: 70 },
  { id: 'title', label: 'Title', minWidth: 200 },
  { id: 'slug', label: 'Slug', minWidth: 200 },
  {
    id: 'status',
    label: 'Status',
    minWidth: 100,
    format: (value: 'ACTIVE' | 'INACTIVE') => (
      <Chip
        label={value}
        color={value === 'ACTIVE' ? 'success' : 'default'}
        size="small"
      />
    ),
  },
  {
    id: 'created_at',
    label: 'Created At',
    minWidth: 150,
    format: (value: string) => format(new Date(value), 'dd/MM/yyyy HH:mm'),
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 100,
    align: 'right',
  },
];

export const PagesListPage = () => {
  const navigate = useNavigate();
  const [pages, setPages] = useState<Page[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState<'ACTIVE' | 'INACTIVE' | ''>('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [pageToDelete, setPageToDelete] = useState<Page | null>(null);

  const fetchPages = useCallback(async (signal?: AbortSignal) => {
    try {
      setLoading(true);
      setError(null);
      const { pages: fetchedPages, total: totalCount } = await pagesService.getPagesList({
        page: page + 1,
        limit: rowsPerPage,
        search: searchQuery,
        status: statusFilter || undefined,
      }, signal);
      
      if (signal?.aborted) return;
      
      setPages(fetchedPages);
      setTotal(totalCount);
    } catch (err) {
      if (signal?.aborted) return;
      const errorMessage = err instanceof Error ? err.message : 'Failed to fetch pages';
      setError(errorMessage);
    } finally {
      if (!signal?.aborted) {
        setLoading(false);
      }
    }
  }, [page, rowsPerPage, searchQuery, statusFilter]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchPages(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [fetchPages]);

  const handleDeletePage = async () => {
    if (!pageToDelete) return;

    const abortController = new AbortController();
    try {
      setLoading(true);
      await pagesService.deletePage(pageToDelete.id, abortController.signal);
      setDeleteDialogOpen(false);
      setPageToDelete(null);
      fetchPages();
    } catch (err) {
      if (!abortController.signal.aborted) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to delete page';
        setError(errorMessage);
      }
    } finally {
      if (!abortController.signal.aborted) {
        setLoading(false);
      }
    }
  };

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
    setPage(0);
  };

  const handleStatusFilterChange = (status: 'ACTIVE' | 'INACTIVE' | '') => {
    setStatusFilter(status);
    setPage(0);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const renderActions = (page: Page) => (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
      <Tooltip title="Edit">
        <IconButton
          size="small"
          onClick={() => navigate(`/pages/${page.id}`)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          size="small"
          onClick={() => {
            setPageToDelete(page);
            setDeleteDialogOpen(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const enrichedPages = pages.map((page) => ({
    ...page,
    actions: renderActions(page),
  }));

  return (
    <Container maxWidth={false} sx={{ p: 3 }}>
      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h4" component="h1">
            Pages
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate('/pages/new')}
          >
            Create Page
          </Button>
        </Box>

        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <TextField
            label="Search"
            value={searchQuery}
            onChange={(e) => handleSearchChange(e.target.value)}
            size="small"
            sx={{ minWidth: 200 }}
          />
          <FormControl size="small" sx={{ minWidth: 200 }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={statusFilter}
              label="Status"
              onChange={(e) => handleStatusFilterChange(e.target.value as 'ACTIVE' | 'INACTIVE' | '')}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="ACTIVE">Active</MenuItem>
              <MenuItem value="INACTIVE">Inactive</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Box>

      <DataTable
        columns={columns}
        rows={enrichedPages}
        page={page}
        rowsPerPage={rowsPerPage}
        total={total}
        loading={loading}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Page</DialogTitle>
        <DialogContent id="delete-dialog-description">
          Are you sure you want to delete this page?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={handleDeletePage}
            color="error"
            disabled={loading}
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}; 