import axios from 'axios';
import { authService } from './authService';

interface MenuItem {
  label: string;
  url: string;
}

interface Menu {
  title: string;
  items: MenuItem[];
}

export interface FooterSettings {
  id?: number;
  name: string;
  logo_path: string;
  menus: Menu[];
  is_deleted?: number;
  created_at?: string;
  updated_at?: string;
}

interface ApiMenuItem {
  id?: number;
  text: string;
  link: string;
  sub_menu?: ApiMenuItem[];
}

interface ApiFooterData {
  id: number;
  name: string;
  logo_path: string;
  menus: ApiMenuItem[];
  is_deleted: number;
  created_at: string;
  updated_at: string;
}

interface ApiListResponse {
  success: boolean;
  data: {
    data: ApiFooterData[];
    meta: {
      current_page: number;
      per_page: number;
      total: number;
      total_pages: number;
    };
  };
  message?: string;
}

interface ApiSingleResponse {
  success: boolean;
  data: ApiFooterData;
  message?: string;
}

type ApiResponse = ApiListResponse | ApiSingleResponse;

const BASE_URL = import.meta.env.VITE_API_URL || 'http://localhost:3232';

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
});

api.interceptors.request.use(
  (config) => {
    const token = authService.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const convertApiMenuToMenu = (apiMenu: ApiMenuItem): Menu => {
  const items: MenuItem[] = [];
  
  // Ana menü öğesini ekle
  items.push({
    label: apiMenu.text,
    url: apiMenu.link
  });

  // Alt menü öğelerini ekle
  if (apiMenu.sub_menu) {
    apiMenu.sub_menu.forEach(subItem => {
      items.push({
        label: subItem.text,
        url: subItem.link
      });
    });
  }

  return {
    title: apiMenu.text,
    items
  };
};

const convertMenuToApiMenu = (menu: Menu): ApiMenuItem => {
  return {
    text: menu.title || '',
    link: menu.items[0]?.url || '#',
    sub_menu: menu.items.slice(1).map(item => ({
      text: item.label || '',
      link: item.url || '#'
    }))
  };
};

export const footerSettingsService = {
  getFooterSettings: async (id: number): Promise<FooterSettings> => {
    try {
      const response = await api.get<ApiSingleResponse>(`/api/footer-settings/${id}`);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Footer ayarları alınamadı');
      }

      const footerData = response.data.data;
      return {
        id: footerData.id,
        name: footerData.name,
        logo_path: footerData.logo_path,
        menus: footerData.menus.map(convertApiMenuToMenu),
        is_deleted: footerData.is_deleted,
        created_at: footerData.created_at,
        updated_at: footerData.updated_at
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Footer ayarları alınamadı'
        );
      }
      throw error;
    }
  },

  getAllFooterSettings: async (): Promise<{ settings: FooterSettings[]; meta: any }> => {
    try {
      const response = await api.get<ApiListResponse>('/api/footer-settings');

      if (!response.data.success) {
        throw new Error(response.data.message || 'Footer ayarları listelenemedi');
      }

      const settings = response.data.data.data.map((footerData: ApiFooterData) => ({
        id: footerData.id,
        name: footerData.name,
        logo_path: footerData.logo_path,
        menus: footerData.menus.map(convertApiMenuToMenu),
        is_deleted: footerData.is_deleted,
        created_at: footerData.created_at,
        updated_at: footerData.updated_at
      }));

      return {
        settings,
        meta: response.data.data.meta,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Footer ayarları listelenemedi'
        );
      }
      throw error;
    }
  },

  createFooterSettings: async (data: Omit<FooterSettings, 'id'>): Promise<FooterSettings> => {
    try {
      const apiData = {
        name: data.name,
        logo_path: data.logo_path,
        menus: data.menus.map(convertMenuToApiMenu)
      };

      const response = await api.post<ApiSingleResponse>('/api/footer-settings', apiData);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Footer ayarları oluşturulamadı');
      }

      const footerData = response.data.data;
      return {
        id: footerData.id,
        name: footerData.name,
        logo_path: footerData.logo_path,
        menus: footerData.menus.map(convertApiMenuToMenu),
        is_deleted: footerData.is_deleted,
        created_at: footerData.created_at,
        updated_at: footerData.updated_at
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Footer ayarları oluşturulamadı'
        );
      }
      throw error;
    }
  },

  updateFooterSettings: async (id: number, data: Partial<FooterSettings>): Promise<FooterSettings> => {
    try {
      const apiData = {
        name: data.name,
        logo_path: data.logo_path,
        menus: data.menus?.map(convertMenuToApiMenu)
      };

      console.log('Sending update request with data:', apiData);

      const response = await api.put<ApiSingleResponse>(`/api/footer-settings/${id}`, apiData);
      console.log('Update API response:', response.data);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Footer ayarları güncellenemedi');
      }

      const footerData = response.data.data;
      return {
        id: footerData.id || id,
        name: footerData.name || data.name || '',
        logo_path: footerData.logo_path || data.logo_path || '',
        menus: Array.isArray(footerData.menus) 
          ? footerData.menus.map(convertApiMenuToMenu)
          : data.menus || [],
        is_deleted: footerData.is_deleted || 0,
        created_at: footerData.created_at || new Date().toISOString(),
        updated_at: footerData.updated_at || new Date().toISOString()
      };
    } catch (error) {
      console.error('Update footer settings error:', error);
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          authService.logout();
        }
        console.error('API Error response:', error.response?.data);
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Footer ayarları güncellenemedi'
        );
      }
      throw error;
    }
  },

  deleteFooterSettings: async (id: number): Promise<void> => {
    try {
      const response = await api.delete<ApiResponse>(`/api/footer-settings/${id}`);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Footer ayarları silinemedi');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Footer ayarları silinemedi'
        );
      }
      throw error;
    }
  },

  uploadLogo: async (file: File): Promise<string> => {
    try {
      const formData = new FormData();
      formData.append('logo', file);

      const response = await api.post<{ success: boolean; data: { path: string }; message?: string }>(
        '/api/footer-settings/upload-logo',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (!response.data.success) {
        throw new Error(response.data.message || 'Logo yüklenemedi');
      }

      return response.data.data.path;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Logo yüklenemedi'
        );
      }
      throw error;
    }
  },
}; 