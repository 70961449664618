import { BrowserRouter, useRoutes } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { theme } from './theme';
import { routes } from './routes';
import { Suspense } from 'react';
import { CircularProgress, Box } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { VideoProcessingProvider } from './contexts/VideoProcessingContext';
import { GoogleMapsProvider } from './components/GoogleMapsProvider';

// Loading component for suspense fallback
const Loader = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}
  >
    <CircularProgress />
  </Box>
);

// Router component using useRoutes hook
const Router = () => {
  const element = useRoutes(routes);
  return <Suspense fallback={<Loader />}>{element}</Suspense>;
};

function App() {
  return (
    <GoogleMapsProvider>
      <VideoProcessingProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider maxSnack={3}>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </VideoProcessingProvider>
    </GoogleMapsProvider>
  );
}

export default App;
