import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'left' | 'center';
  format?: (value: any, row?: any) => string | JSX.Element;
}

export interface DataTableProps {
  columns: Column[];
  rows: any[];
  total?: number;
  totalRows?: number;
  page?: number;
  rowsPerPage?: number;
  loading?: boolean;
  error?: string;
  searchValue?: string;
  onPageChange?: (page: number) => void;
  onRowsPerPageChange?: (rowsPerPage: number) => void;
  onSearchChange?: (query: string) => void;
  onRowClick?: (row: any) => void;
  additionalControls?: React.ReactNode;
}

export const DataTable = ({
  columns,
  rows,
  totalRows = 0,
  page = 0,
  rowsPerPage = 10,
  loading = false,
  error,
  searchValue = '',
  onPageChange,
  onRowsPerPageChange,
  onSearchChange,
  onRowClick,
  additionalControls,
}: DataTableProps) => {
  const [tableHeight, setTableHeight] = useState('calc(100vh - 300px)');

  useEffect(() => {
    const calculateHeight = () => {
      // Get the viewport height
      const vh = window.innerHeight;
      // Calculate table height (viewport height - header - search bar - pagination - padding)
      const height = vh - 300; // 300px for all other elements
      setTableHeight(`${height}px`);
    };

    // Calculate initial height
    calculateHeight();

    // Add resize listener
    window.addEventListener('resize', calculateHeight);

    // Cleanup
    return () => window.removeEventListener('resize', calculateHeight);
  }, []);

  const handleChangePage = (_event: unknown, newPage: number) => {
    onPageChange?.(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onRowsPerPageChange?.(parseInt(event.target.value, 10));
    onPageChange?.(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      {/* Search and Controls */}
      <Box sx={{ 
        p: 2, 
        display: 'flex', 
        alignItems: 'center',
        gap: 2,
        borderBottom: '1px solid',
        borderColor: 'divider'
      }}>
        <Box sx={{ flex: 1 }}>
          <TextField
            placeholder="Search..."
            variant="outlined"
            size="small"
            fullWidth
            value={searchValue}
            onChange={(e) => onSearchChange?.(e.target.value)}
          />
        </Box>
        {additionalControls}
      </Box>

      {/* Table */}
      <TableContainer sx={{ height: tableHeight }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sx={{
                    backgroundColor: 'background.paper',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    py: 2
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center" sx={{ py: 4 }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={columns.length} sx={{ py: 2 }}>
                  <Alert severity="error">{error}</Alert>
                </TableCell>
              </TableRow>
            ) : rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center" sx={{ py: 4 }}>
                  No data available
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  onClick={() => onRowClick?.(row)}
                  sx={{ 
                    cursor: onRowClick ? 'pointer' : 'default',
                    '& td': {
                      py: 1.5,
                      px: 2,
                      height: 'auto',
                      whiteSpace: 'normal',
                      verticalAlign: 'top'
                    },
                    '&:hover': {
                      backgroundColor: 'action.hover'
                    }
                  }}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell 
                        key={column.id} 
                        align={column.align}
                      >
                        {column.format ? column.format(value, row) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}; 