import axios from 'axios';
import { authService } from './authService';

export interface HeroCTA {
  text: string;
  url: string;
}

export interface HomeButton {
  text: string;
  url: string;
  image_url: string;
}

export interface HomepageSettings {
  hero_title: string;
  hero_subtitle: string;
  hero_cta: HeroCTA;
  buttons: HomeButton[];
}

export interface HomepageSettingsResponse {
  success: boolean;
  data: HomepageSettings;
  message?: string;
}

const BASE_URL = import.meta.env.VITE_API_URL || 'http://localhost:3232';

// Create axios instance with base configuration
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
});

// Add request interceptor for authentication
api.interceptors.request.use(
  (config) => {
    const token = authService.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const homepageSettingsService = {
  getHomepageSettings: async (): Promise<HomepageSettings> => {
    try {
      const response = await api.get<HomepageSettingsResponse>('/api/homepage-settings');

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to fetch homepage settings');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to fetch homepage settings'
        );
      }
      throw error;
    }
  },

  updateHomepageSettings: async (data: HomepageSettings): Promise<HomepageSettings> => {
    try {
      const response = await api.put<HomepageSettingsResponse>('/api/homepage-settings', data);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to update homepage settings');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to update homepage settings'
        );
      }
      throw error;
    }
  },

  uploadButtonImage: async (file: File): Promise<string> => {
    try {
      const formData = new FormData();
      formData.append('image', file);

      const response = await api.post<{ success: boolean; data: { path: string }; message?: string }>(
        '/api/homepage-settings/upload-button-image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to upload button image');
      }

      return response.data.data.path;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to upload button image'
        );
      }
      throw error;
    }
  },
}; 