import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Grid,
  Paper,
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { KanbanStagesPage } from './settings/KanbanStagesPage';
import { HomepageSettingsPage } from './settings/HomepageSettingsPage';
import { HeaderSettingsPage } from './settings/HeaderSettingsPage';
import { FooterSettingsPage } from './settings/FooterSettingsPage';
import SeoSettings from './settings/SeoSettings';

interface MenuItem {
  text: string;
  emoji: string;
  path: string;
  component: React.ReactNode;
}

interface MenuSection {
  title: string;
  items: MenuItem[];
}

const menuSections: MenuSection[] = [
  {
    title: 'ARAÇLAR',
    items: [
      {
        text: 'Kanban Stages',
        emoji: '🎯',
        path: '/settings/kanban-stages',
        component: <KanbanStagesPage />,
      },
    ],
  },
  {
    title: 'WEBTOOLS',
    items: [
      {
        text: 'Homepage',
        emoji: '🌟',
        path: '/settings/homepage',
        component: <HomepageSettingsPage />,
      },
      {
        text: 'Header',
        emoji: '🎨',
        path: '/settings/header',
        component: <HeaderSettingsPage />,
      },
      {
        text: 'SEO',
        emoji: '🔍',
        path: '/settings/seo',
        component: <SeoSettings />,
      },
      {
        text: 'Footer',
        emoji: '🔮',
        path: '/settings/footer',
        component: <FooterSettingsPage />,
      },
    ],
  },
];

export const SettingsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedPath, setSelectedPath] = useState(location.pathname);

  // URL değiştiğinde seçili path'i güncelle
  useEffect(() => {
    setSelectedPath(location.pathname);
  }, [location.pathname]);

  // İlk yüklemede varsayılan sayfaya yönlendir
  useEffect(() => {
    if (location.pathname === '/settings') {
      navigate('/settings/kanban-stages', { replace: true });
    }
  }, [navigate, location.pathname]);

  const handleMenuClick = (path: string) => {
    navigate(path);
    setSelectedPath(path);
  };

  const selectedItem = menuSections
    .flatMap((section) => section.items)
    .find((item) => item.path === selectedPath);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Side Menu */}
        <Grid item xs={12} md={3}>
          <Paper sx={{ width: '100%' }}>
            {menuSections.map((section) => (
              <List
                key={section.title}
                subheader={
                  <ListSubheader sx={{ bgcolor: 'background.paper' }}>
                    {section.title}
                  </ListSubheader>
                }
              >
                {section.items.map((item) => (
                  <ListItemButton
                    key={item.path}
                    selected={selectedPath === item.path}
                    onClick={() => handleMenuClick(item.path)}
                  >
                    <ListItemIcon sx={{ minWidth: 40, fontSize: '1.2rem' }}>
                      {item.emoji}
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                ))}
              </List>
            ))}
          </Paper>
        </Grid>

        {/* Content Area */}
        <Grid item xs={12} md={9}>
          <Paper sx={{ p: 3, minHeight: '500px' }}>
            {selectedItem?.component}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}; 