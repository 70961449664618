import axios from 'axios';
import { authService } from './authService';

export interface Clinic {
  id: number | string;
  title: string;
  slug: string;
  address: string;
  phone: string;
  phone_ext: string;
  category_id: number;
  status: 'active' | 'inactive';
  logo?: string;
  about?: string;
  google_place_id?: string;
  trustpilot_url?: string;
  created_at?: string;
  updated_at?: string;
  location?: {
    lat: number;
    lng: number;
  };
  speaking?: string[];
}

export interface ClinicCreateInput {
  title: string;
  slug: string;
  address: string;
  phone: string;
  category_id: number;
  services: number[];
  status: 'active' | 'inactive';
  logo?: File;
}

export interface ClinicUpdateInput {
  title?: string;
  slug?: string;
  description?: string;
  address?: string;
  category_id?: number;
  logo?: File;
  about?: string;
  phone?: string;
  phone_ext?: string;
  status?: 'active' | 'inactive';
  location?: {
    lat: number;
    lng: number;
  };
  google_place_id?: string;
  trustpilot_url?: string;
  speaking?: string[];
}

export interface ClinicListParams {
  page?: number;
  per_page?: number;
  limit?: number;
  search?: string;
  category_id?: number;
}

export interface ClinicListResponse {
  success: boolean;
  data: {
    clinics: Clinic[];
    current_page: number;
    per_page: number;
    total: number;
    total_pages: number;
  };
  message?: string;
}

export interface ClinicResponse {
  success: boolean;
  data: Clinic;
  message?: string;
}

export interface Service {
  id: number;
  clinic_id: number;
  title: string;
  content: string;
  price_min: number;
  price_max: number;
  rank: number;
  status: string;
  is_deleted: number;
  created_at: string;
  updated_at: string;
}

export interface GetClinicServicesResponse {
  success: boolean;
  data: {
    success: boolean;
    data: Service[];
    meta: {
      current_page: number;
      per_page: number;
      total: number;
      total_pages: number;
    }
  }
}

export interface Review {
  Source: string;
  ReviewID: string;
  AuthorName: string;
  AuthorProfileImage: string;
  Rating: number;
  ReviewText: string;
  ReviewDate: string;
  ClinicReply: string;
  ReplyDate: string | null;
}

export interface GetClinicReviewsResponse {
  success: boolean;
  data: Review[];
  rating: number;
  total_reviews: number;
}

export interface ClinicPhoneUpdateInput {
  phone: string;
  phone_ext: string;
}

export interface ClinicLocationUpdateInput {
  location: {
    lat: number;
    lng: number;
  };
}

const BASE_URL = import.meta.env.VITE_API_URL || 'http://localhost:3232';

// Create axios instance with base configuration
export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
});

// Add request interceptor for authentication
api.interceptors.request.use(
  (config) => {
    const token = authService.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const clinicService = {
  getClinics: async (params?: ClinicListParams) => {
    try {
      const queryParams = new URLSearchParams();
      if (params?.page) queryParams.append('page', params.page.toString());
      if (params?.per_page) queryParams.append('per_page', params.per_page.toString());
      if (params?.search) queryParams.append('search', params.search);
      if (params?.category_id) queryParams.append('category_id', params.category_id.toString());
      
      const response = await api.get<ClinicListResponse>(`/api/clinics?${queryParams.toString()}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching clinics:', error);
      throw error;
    }
  },

  getClinic: async (id: string): Promise<Clinic> => {
    try {
      const response = await api.get<ClinicResponse>(`/api/clinics/${id}`);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to fetch clinic');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to fetch clinic'
        );
      }
      throw error;
    }
  },

  createClinic: async (data: ClinicCreateInput): Promise<Clinic> => {
    try {
      const response = await api.post<ClinicResponse>('/api/clinics', data);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to create clinic');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to create clinic'
        );
      }
      throw error;
    }
  },

  updateClinic: async (id: string | number, data: ClinicUpdateInput): Promise<Clinic> => {
    try {
      console.log('Updating clinic with data:', data);
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (key === 'services') {
          const numericServices = value.map((id: number | string) => Number(id));
          formData.append(key, JSON.stringify(numericServices));
        } else if (key === 'logo' && value instanceof File) {
          formData.append('logo', value);
        } else if (value !== undefined && value !== null) {
          formData.append(key, value);
        }
      });

      const response = await api.put<ClinicResponse>(`/api/clinics/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to update clinic');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data) {
        console.error('Error details:', error.response.data);
        throw new Error(
          error.response.data.message ||
          error.response.statusText ||
          'Failed to update clinic'
        );
      }
      throw new Error('An unexpected error occurred while updating clinic');
    }
  },

  deleteClinic: async (id: string | number): Promise<void> => {
    try {
      const response = await api.delete<ClinicResponse>(`/api/clinics/${id}`);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to delete clinic');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to delete clinic'
        );
      }
      throw error;
    }
  },

  async getClinicServices(clinicId: string | number, params?: { page?: number; limit?: number }): Promise<GetClinicServicesResponse> {
    const queryParams = new URLSearchParams();
    queryParams.append('clinic_id', clinicId.toString());
    
    if (params?.page) {
      queryParams.append('page', params.page.toString());
    }
    if (params?.limit) {
      queryParams.append('limit', params.limit.toString());
    }
    
    const response = await api.get<GetClinicServicesResponse>(`/api/services?${queryParams.toString()}`);
    return response.data;
  },

  getClinicReviews: async (clinicId: number | string): Promise<GetClinicReviewsResponse> => {
    try {
      const response = await api.get<GetClinicReviewsResponse>(`/api/clinics/${clinicId}/reviews`);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to fetch clinic reviews'
        );
      }
      throw error;
    }
  },

  updateGooglePlaceId: async (clinicId: number | string, data: { google_place_id: string }) => {
    try {
      console.log('Service - Making Google Place ID update request:', {
        url: `/api/clinics/${clinicId}/google-place-id`,
        method: 'PUT',
        data,
        clinicId
      });
      const response = await api.put<{ success: boolean }>(
        `/api/clinics/${clinicId}/google-place-id`,
        data
      );
      console.log('Service - Google Place ID update response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating Google Place ID:', {
        error,
        clinicId,
        data,
        errorDetails: axios.isAxiosError(error) ? {
          response: error.response?.data,
          status: error.response?.status,
          statusText: error.response?.statusText
        } : error
      });
      throw error;
    }
  },

  updateTrustpilotUrl: async (clinicId: number | string, data: { trustpilot_url: string }) => {
    try {
      console.log('Service - Making Trustpilot URL update request:', {
        url: `/api/clinics/${clinicId}/trustpilot-url`,
        method: 'PUT',
        data,
        clinicId
      });
      const response = await api.put<{ success: boolean }>(
        `/api/clinics/${clinicId}/trustpilot-url`,
        data
      );
      console.log('Service - Trustpilot URL update response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating Trustpilot URL:', {
        error,
        clinicId,
        data,
        errorDetails: axios.isAxiosError(error) ? {
          response: error.response?.data,
          status: error.response?.status,
          statusText: error.response?.statusText
        } : error
      });
      throw error;
    }
  },

  saveReviews: async (clinicId: number | string, reviewData: any) => {
    try {
      console.log('Review data received:', reviewData);

      // Check if it's a Google review (has Source field) or Trustpilot review
      const data = reviewData.Source ? {
        // Google format
        source: reviewData.Source,
        author_name: reviewData.AuthorName,
        author_profile_image: reviewData.AuthorProfileImage,
        rating: reviewData.Rating,
        review_text: reviewData.ReviewText,
        review_date: reviewData.ReviewDate
      } : {
        // Trustpilot format
        source: "trustpilot",
        title: reviewData.title || "Trustpilot Review",
        author_name: reviewData.author?.name || reviewData.author_name,
        author_profile_image: reviewData.author?.profile_image || reviewData.author_profile_image || "",
        rating: reviewData.rating,
        review_text: reviewData.text || reviewData.review_text || "",
        review_date: reviewData.date || reviewData.review_date,
        clinic_reply: reviewData.reply?.text || reviewData.clinic_reply || "",
        reply_date: reviewData.reply?.date || reviewData.reply_date || null
      };

      console.log('Making API request to save review:', {
        url: `/api/clinics/${clinicId}/reviews`,
        data
      });

      const response = await api.post(
        `/api/clinics/${clinicId}/reviews`,
        data
      );

      console.log('API Response:', response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('API Error:', error.response?.data);
        throw new Error(
          error.response?.data?.error ||
          error.response?.data?.message ||
          'Failed to save review'
        );
      }
      throw error;
    }
  },

  updateGoogleData: async (clinicId: number | string, rating: number, total_reviews: number) => {
    try {
      const response = await api.put<{ success: boolean }>(
        `/api/clinics/${clinicId}/google-data`,
        {
          google_data: {
            rating,
            total_reviews
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error updating Google data:', error);
      throw error;
    }
  },

  updateTrustpilotData: async (clinicId: number | string, rating: number, total_reviews: number) => {
    try {
      const response = await api.put<{ success: boolean }>(
        `/api/clinics/${clinicId}/trustpilot-data`,
        {
          trustpilot_data: {
            rating,
            total_reviews
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error updating Trustpilot data:', error);
      throw error;
    }
  },

  checkReviewsExist: async (clinicId: number | string, source: 'google_maps' | 'trustpilot') => {
    try {
      const response = await api.get<{ count: number; has_reviews: boolean }>(
        `/api/clinics/${clinicId}/reviews/check/${source}`
      );
      return {
        exists: response.data.has_reviews,
        count: response.data.count
      };
    } catch (error) {
      console.error(`Error checking ${source} reviews:`, error);
      throw error;
    }
  },

  deleteAllReviews: async (clinicId: number | string, source: 'google_maps' | 'trustpilot') => {
    try {
      const response = await api.delete<{ success: boolean }>(
        `/api/clinics/${clinicId}/reviews/${source}`
      );
      return response.data;
    } catch (error) {
      console.error(`Error deleting ${source} reviews:`, error);
      throw error;
    }
  },

  updateClinicTreatments: async (clinicId: string | number, treatmentIds: number[]): Promise<void> => {
    try {
      const response = await api.put(`/api/clinics/${clinicId}/services`, {
        services: treatmentIds
      });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to update clinic treatments');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to update clinic treatments'
        );
      }
      throw error;
    }
  },

  updateClinicPhone: async (id: number, data: ClinicPhoneUpdateInput) => {
    const response = await api.put(`${BASE_URL}/api/clinics/${id}/phone`, data);
    return response.data;
  },

  updateClinicTitle: async (id: number | string, title: string) => {
    try {
      const response = await api.put(`/api/clinics/${id}/title`, { title });
      return response.data;
    } catch (error) {
      console.error('Error updating clinic title:', error);
      throw error;
    }
  },

  updateClinicLocation: async (id: number, data: ClinicLocationUpdateInput) => {
    const response = await api.put(`/api/clinics/${id}/location`, data);
    return response.data;
  },

  async updateClinicSpeakingLanguages(clinicId: number | string, languages: string[]) {
    const response = await api.put(
      `/api/clinics/${clinicId}/speaking`,
      { speaking: languages },
      {
        headers: {
          Authorization: `Bearer ${authService.getToken()}`
        }
      }
    );
    return response.data;
  },

  getTrustpilotReviews: async (trustpilotUrl: string) => {
    try {
      const response = await api.get<{
        clinic_score: number;
        reviews: {
          author: {
            name: string;
            review_count: number;
            profile_image?: string;
          };
          date: string;
          experience_date: string;
          rating: number;
          text: string;
          title: string;
          reply?: {
            company: string;
            date: string;
            text: string;
          };
        }[];
        total_reviews: number;
      }>(`/api/trustpilot/reviews?url=${encodeURIComponent(trustpilotUrl)}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching Trustpilot reviews:', error);
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          throw new Error('Trustpilot reviews integration is currently under development. Please try again later.');
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to fetch Trustpilot reviews. Please check the URL and try again.'
        );
      }
      throw error;
    }
  },
};