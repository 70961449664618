import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { VideoUploadProgress, videoService } from '../services/videoService';
import { toast } from 'react-hot-toast';

const STORAGE_KEY = 'processingVideos';

interface ProcessingVideo {
  clinicId: number | string;
  title: string;
  progress: VideoUploadProgress;
  startTime: number;
}

interface VideoProcessingContextType {
  processingVideos: ProcessingVideo[];
  startVideoProcessing: (clinicId: number | string, title: string) => void;
  updateVideoProgress: (clinicId: number | string, progress: VideoUploadProgress) => void;
  removeVideoProcessing: (clinicId: number | string) => void;
}

const VideoProcessingContext = createContext<VideoProcessingContextType | null>(null);

export const useVideoProcessing = () => {
  const context = useContext(VideoProcessingContext);
  if (!context) {
    throw new Error('useVideoProcessing must be used within a VideoProcessingProvider');
  }
  return context;
};

export const VideoProcessingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Initialize state from localStorage
  const [processingVideos, setProcessingVideos] = useState<ProcessingVideo[]>(() => {
    try {
      const saved = localStorage.getItem(STORAGE_KEY);
      return saved ? JSON.parse(saved) : [];
    } catch {
      return [];
    }
  });

  // Save to localStorage whenever processingVideos changes
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(processingVideos));
  }, [processingVideos]);

  // Check video status periodically
  useEffect(() => {
    const checkVideoStatus = async (video: ProcessingVideo) => {
      try {
        const response = await videoService.getClinicVideo(video.clinicId);
        
        if (!response.data) {
          throw new Error('Video not found');
        }

        if (response.data.status === 'ready') {
          toast.success(`Video "${video.title}" has been processed successfully`);
          setProcessingVideos(prev => prev.filter(v => v.clinicId !== video.clinicId));
        } else if (response.data.status === 'failed') {
          toast.error(`Failed to process video "${video.title}"`);
          setProcessingVideos(prev => prev.filter(v => v.clinicId !== video.clinicId));
        }
      } catch (error) {
        // Only remove from processing if it's been more than 30 minutes
        const now = Date.now();
        if (now - video.startTime > 30 * 60 * 1000) {
          console.error('Error checking video status:', error);
          toast.error(`Error checking video "${video.title}" status`);
          setProcessingVideos(prev => prev.filter(v => v.clinicId !== video.clinicId));
        }
      }
    };

    const interval = setInterval(() => {
      processingVideos.forEach(video => {
        if (video.progress.status === 'processing') {
          checkVideoStatus(video);
        }
      });
    }, 10000);

    return () => clearInterval(interval);
  }, [processingVideos]);

  const startVideoProcessing = useCallback((clinicId: number | string, title: string) => {
    setProcessingVideos(prev => {
      if (prev.some(v => v.clinicId === clinicId)) return prev;
      return [...prev, {
        clinicId,
        title,
        progress: { progress: 0, status: 'uploading' },
        startTime: Date.now()
      }];
    });
  }, []);

  const updateVideoProgress = useCallback((clinicId: number | string, progress: VideoUploadProgress) => {
    setProcessingVideos(prev => prev.map(video => 
      video.clinicId === clinicId ? { ...video, progress } : video
    ));
  }, []);

  const removeVideoProcessing = useCallback((clinicId: number | string) => {
    setProcessingVideos(prev => prev.filter(video => video.clinicId !== clinicId));
  }, []);

  return (
    <VideoProcessingContext.Provider value={{
      processingVideos,
      startVideoProcessing,
      updateVideoProgress,
      removeVideoProcessing
    }}>
      {children}
    </VideoProcessingContext.Provider>
  );
}; 