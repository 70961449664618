import axios from 'axios';
import { authService } from './authService';

export interface ClinicLogin {
  id: string;
  clinic_id: number;
  clinic_mail: string;
  status: string;
  clinic_title?: string;
  created_at?: string;
  updated_at?: string;
}

export interface ClinicLoginCreateInput {
  clinic_id: number;
  clinic_mail: string;
  password: string;
}

export interface ClinicLoginUpdateInput {
  clinic_mail?: string;
  password?: string;
  status?: 'active' | 'inactive';
}

export interface ClinicLoginListResponse {
  success: boolean;
  data: ClinicLogin[];
  meta: {
    current_page: number;
    per_page: number;
    total: number;
    total_pages: number;
  };
  message?: string;
}

export interface ClinicLoginResponse {
  success: boolean;
  data: ClinicLogin;
  message?: string;
}

export interface ClinicLoginListParams {
  page?: number;
  limit?: number;
  search?: string;
}

const BASE_URL = import.meta.env.VITE_API_URL || 'http://localhost:3232';

// Create axios instance with base configuration
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
});

// Add request interceptor for authentication
api.interceptors.request.use(
  (config) => {
    const token = authService.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const clinicLoginsService = {
  getClinicLogins: async ({ page = 1, limit = 10, search = '' }: ClinicLoginListParams = {}, signal?: AbortSignal) => {
    try {
      const response = await api.get<ClinicLoginListResponse>('/api/clinic-logins', {
        params: {
          page,
          per_page: limit,
          search,
        },
        signal,
      });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to fetch clinic logins');
      }

      return {
        clinicLogins: response.data.data,
        total: response.data.meta.total || 0
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.name === 'CanceledError' || error.code === 'ERR_CANCELED') {
          throw new Error('Request was cancelled');
        }
        
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to fetch clinic login list'
        );
      }
      throw error;
    }
  },

  getClinicLogin: async (id: string): Promise<ClinicLogin> => {
    try {
      const response = await api.get<ClinicLoginResponse>(`/api/clinic-logins/${id}`);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to fetch clinic login');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to fetch clinic login'
        );
      }
      throw error;
    }
  },

  createClinicLogin: async (data: ClinicLoginCreateInput): Promise<ClinicLogin> => {
    try {
      const response = await api.post<ClinicLoginResponse>('/api/clinic-logins', data);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to create clinic login');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to create clinic login'
        );
      }
      throw error;
    }
  },

  updateClinicLogin: async (id: string, data: ClinicLoginUpdateInput): Promise<ClinicLogin> => {
    try {
      const response = await api.put<ClinicLoginResponse>(`/api/clinic-logins/${id}`, data);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to update clinic login');
      }

      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to update clinic login'
        );
      }
      throw error;
    }
  },

  deleteClinicLogin: async (id: string): Promise<void> => {
    try {
      const response = await api.delete<ClinicLoginResponse>(`/api/clinic-logins/${id}`);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to delete clinic login');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          authService.logout();
        }
        throw new Error(
          error.response?.data?.message ||
          error.response?.statusText ||
          'Failed to delete clinic login'
        );
      }
      throw error;
    }
  },
}; 