import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Paper,
  TextField,
  Button,
  Alert,
  Typography,
  Grid,
  CircularProgress,
  IconButton,
  Card,
  CardContent,
  InputAdornment,
} from '@mui/material';
import { Upload as UploadIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { headerSettingsService, HeaderSettings } from '../../services/headerSettingsService';
import axios from 'axios';

const initialSettings: HeaderSettings = {
  logo_path: '',
  add_clinic_text: 'Kliniğinizi Ekleyin',
  add_clinic_url: '/clinic-requests',
};

export const HeaderSettingsPage = () => {
  const [settings, setSettings] = useState<HeaderSettings>(initialSettings);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [logoPreview, setLogoPreview] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const abortController = new AbortController();
    fetchSettings(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (settings.logo_path) {
      setLogoPreview(settings.logo_path);
    }
  }, [settings.logo_path]);

  const fetchSettings = async (signal?: AbortSignal) => {
    try {
      setLoading(true);
      setError(null);
      const data = await headerSettingsService.getHeaderSettings(signal);
      if (signal?.aborted) return;
      setSettings(data);
    } catch (err) {
      if (signal?.aborted) return;
      
      if (axios.isAxiosError(err)) {
        if (err.code === 'ERR_CANCELED') {
          return;
        }
        // API henüz oluşturulmadıysa varsayılan değerleri kullan
        if (err.response?.status === 404) {
          setSettings(initialSettings);
        } else {
          setError(err instanceof Error ? err.message : 'Header ayarları yüklenemedi');
        }
      }
    } finally {
      if (!signal?.aborted) {
        setLoading(false);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const abortController = new AbortController();
    try {
      setLoading(true);
      setError(null);
      setSuccess(null);
      await headerSettingsService.updateHeaderSettings(settings, abortController.signal);
      if (!abortController.signal.aborted) {
        setSuccess('Header settings updated successfully');
        fetchSettings(abortController.signal);
      }
    } catch (err) {
      if (!abortController.signal.aborted) {
        if (axios.isAxiosError(err)) {
          if (err.code === 'ERR_CANCELED') {
            return;
          }
          setError(err instanceof Error ? err.message : 'Failed to update header settings');
        }
      }
    } finally {
      if (!abortController.signal.aborted) {
        setLoading(false);
      }
    }
    return () => {
      abortController.abort();
    };
  };

  const handleChange = (field: keyof HeaderSettings, value: string) => {
    setSettings((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleLogoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setLoading(true);
      setError(null);
      
      // Create a preview URL
      const previewUrl = URL.createObjectURL(file);
      setLogoPreview(previewUrl);

      // Upload the file
      const logoPath = await headerSettingsService.uploadLogo(file);
      
      // Update settings with the new logo path
      setSettings((prev) => ({
        ...prev,
        logo_path: logoPath,
      }));

      setSuccess('Logo uploaded successfully');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to upload logo');
      // Revert to the previous logo if upload fails
      setLogoPreview(settings.logo_path);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveLogo = () => {
    setSettings((prev) => ({
      ...prev,
      logo_path: '',
    }));
    setLogoPreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  if (loading && !settings.logo_path) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Header Settings
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}

      <Grid container spacing={3}>
        {/* Settings Form */}
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 3 }}>
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <Box>
                  <Typography variant="subtitle1" color="primary" gutterBottom>
                    Logo Settings
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Button
                      variant="outlined"
                      component="label"
                      startIcon={<UploadIcon />}
                      disabled={loading}
                    >
                      Upload Logo
                      <input
                        ref={fileInputRef}
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleLogoUpload}
                      />
                    </Button>
                    {settings.logo_path && (
                      <IconButton
                        color="error"
                        onClick={handleRemoveLogo}
                        disabled={loading}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                </Box>

                <TextField
                  label="Add Clinic Text"
                  value={settings.add_clinic_text}
                  onChange={(e) => handleChange('add_clinic_text', e.target.value)}
                  fullWidth
                  required
                  placeholder="e.g., Add Your Clinic"
                />

                <TextField
                  label="Add Clinic URL"
                  value={settings.add_clinic_url}
                  onChange={(e) => handleChange('add_clinic_url', e.target.value)}
                  fullWidth
                  required
                  placeholder="e.g., /clinic/join"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">/</InputAdornment>
                    ),
                  }}
                />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                  >
                    {loading ? 'Saving...' : 'Save Changes'}
                  </Button>
                </Box>
              </Box>
            </form>
          </Paper>
        </Grid>

        {/* Preview */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, position: 'sticky', top: 20 }}>
            <Typography variant="subtitle1" gutterBottom color="primary">
              Preview
            </Typography>
            <Card variant="outlined">
              <CardContent>
                {logoPreview ? (
                  <Box sx={{ mb: 2, maxWidth: '200px' }}>
                    <img
                      src={logoPreview}
                      alt="Logo Preview"
                      style={{ width: '100%', height: 'auto' }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      mb: 2,
                      width: '200px',
                      height: '60px',
                      bgcolor: 'grey.100',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      No logo uploaded
                    </Typography>
                  </Box>
                )}

                <Button
                  variant="contained"
                  size="small"
                  sx={{ mt: 1 }}
                >
                  {settings.add_clinic_text || 'Add Clinic Button'}
                </Button>

                <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                  Button URL: {settings.add_clinic_url || '/example/url'}
                </Typography>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}; 