import { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  Chip,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { DataTable, Column } from '../components/DataTable';
import { servicesService, Service, ServiceCreateInput, ServiceUpdateInput } from '../services/servicesService';

const columns: Column[] = [
  { id: 'clinic_id', label: 'Clinic ID', minWidth: 50 },
  { id: 'title', label: 'Title', minWidth: 200 },
  { 
    id: 'content', 
    label: 'Content', 
    minWidth: 300,
    format: (value: string) => value?.length > 100 ? `${value.substring(0, 100)}...` : value,
  },
  { 
    id: 'price_range', 
    label: 'Price Range', 
    minWidth: 150,
    format: (_, row: Service) => `$${row.price_min} - $${row.price_max}`,
  },
  {
    id: 'duration',
    label: 'Duration',
    minWidth: 100,
  },
  {
    id: 'recovery',
    label: 'Recovery',
    minWidth: 100,
  },
  {
    id: 'key_features',
    label: 'Features',
    minWidth: 200,
    format: (value: string) => (
      <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
        {value ? value.split(',').map((feature, index) => (
          <Chip
            key={index}
            label={feature.trim()}
            size="small"
            variant="outlined"
            sx={{ borderRadius: '6px' }}
          />
        )) : null}
      </Box>
    ),
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 100,
    format: (value: string) => (
      <Chip
        label={value}
        color={value === 'ACTIVE' ? 'success' : 'default'}
        size="small"
      />
    ),
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 100,
    align: 'right',
  },
];

interface ServiceFormProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: ServiceCreateInput | ServiceUpdateInput) => Promise<void>;
  initialData?: Service;
  loading?: boolean;
  mode: 'create' | 'edit';
}

const ServiceForm = ({
  open,
  onClose,
  onSubmit,
  initialData,
  loading = false,
  mode,
}: ServiceFormProps) => {
  const defaultFormData = {
    clinic_id: 0,
    title: '',
    content: '',
    price_min: 0,
    price_max: 0,
    rank: 0,
    status: 'ACTIVE' as const,
    duration: '',
    recovery: '',
    key_features: '',
  };

  const [formData, setFormData] = useState<ServiceCreateInput>(defaultFormData);

  useEffect(() => {
    if (initialData) {
      setFormData({
        clinic_id: initialData.clinic_id,
        title: initialData.title,
        content: initialData.content,
        price_min: initialData.price_min,
        price_max: initialData.price_max,
        rank: initialData.rank,
        status: initialData.status,
        duration: initialData.duration || '',
        recovery: initialData.recovery || '',
        key_features: initialData.key_features || '',
      });
    } else {
      setFormData(defaultFormData);
    }
  }, [initialData]);

  const handleChange = (field: keyof ServiceCreateInput) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData(prev => ({ ...prev, [field]: event.target.value }));
  };

  const handleClose = () => {
    setFormData(defaultFormData);
    onClose();
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const processedData = {
      ...formData,
      clinic_id: Number(formData.clinic_id),
      price_min: Number(formData.price_min),
      price_max: Number(formData.price_max),
      rank: Number(formData.rank)
    };
    await onSubmit(processedData);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {mode === 'create' ? 'Create Service' : 'Edit Service'}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                label="Clinic ID"
                value={formData.clinic_id}
                onChange={handleChange('clinic_id')}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                value={formData.title}
                onChange={handleChange('title')}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Content"
                value={formData.content}
                onChange={handleChange('content')}
                multiline
                rows={4}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="number"
                label="Minimum Price"
                value={formData.price_min}
                onChange={handleChange('price_min')}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="number"
                label="Maximum Price"
                value={formData.price_max}
                onChange={handleChange('price_max')}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="number"
                label="Rank"
                value={formData.rank}
                onChange={handleChange('rank')}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Duration"
                value={formData.duration}
                onChange={handleChange('duration')}
                placeholder="e.g. 2-3 hours"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Recovery Time"
                value={formData.recovery}
                onChange={handleChange('recovery')}
                placeholder="e.g. 7-10 days"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Key Features"
                value={formData.key_features}
                onChange={handleChange('key_features')}
                placeholder="Enter features separated by commas"
                multiline
                rows={2}
                helperText="Enter features separated by commas (e.g. Feature 1, Feature 2, Feature 3)"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  value={formData.status}
                  label="Status"
                  onChange={(e) => handleChange('status')(e as any)}
                >
                  <MenuItem value="ACTIVE">Active</MenuItem>
                  <MenuItem value="INACTIVE">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : mode === 'create' ? 'Create' : 'Save'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export const ServicesPage = () => {
  const [services, setServices] = useState<Service[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [formOpen, setFormOpen] = useState(false);
  const [selectedService, setSelectedService] = useState<Service | undefined>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState<Service | undefined>();

  useEffect(() => {
    const abortController = new AbortController();
    let isMounted = true;

    const fetchData = async () => {
      try {
        setLoading(true);
        setError(undefined);
        const { services: fetchedServices, total: totalCount } = await servicesService.getServices({
          page: page + 1,
          limit: rowsPerPage,
          search: searchQuery,
        }, abortController.signal);

        if (isMounted) {
          setServices(fetchedServices);
          setTotal(totalCount);
        }
      } catch (err) {
        if (isMounted && err instanceof Error && err.message !== 'Request was cancelled') {
          const errorMessage = err instanceof Error ? err.message : 'Failed to fetch services';
          setError(errorMessage);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [page, rowsPerPage, searchQuery]);

  const handleDeleteService = async () => {
    if (!serviceToDelete) return;

    try {
      setLoading(true);
      await servicesService.deleteService(serviceToDelete.id);
      setDeleteDialogOpen(false);
      setServiceToDelete(undefined);
      setPage(0);
      setRowsPerPage(10);
      setSearchQuery('');
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to delete service';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
    setPage(0);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleCreateService = async (data: ServiceCreateInput) => {
    try {
      await servicesService.createService(data);
      setFormOpen(false);
      setPage(0);
      setRowsPerPage(10);
      setSearchQuery('');
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to create service';
      setError(errorMessage);
    }
  };

  const handleUpdateService = async (data: ServiceUpdateInput) => {
    if (!selectedService) return;

    try {
      await servicesService.updateService(selectedService.id, data);
      setFormOpen(false);
      setSelectedService(undefined);
      setPage(0);
      setRowsPerPage(10);
      setSearchQuery('');
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to update service';
      setError(errorMessage);
    }
  };

  const handleFormSubmit = async (data: ServiceCreateInput | ServiceUpdateInput) => {
    if (selectedService) {
      await handleUpdateService(data as ServiceUpdateInput);
    } else {
      await handleCreateService(data as ServiceCreateInput);
    }
  };

  const renderActions = (service: Service) => (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
      <Tooltip title="Edit">
        <IconButton
          size="small"
          onClick={() => {
            setSelectedService(service);
            setFormOpen(true);
          }}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          size="small"
          onClick={() => {
            setServiceToDelete(service);
            setDeleteDialogOpen(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const enrichedServices = services.map((service: Service) => ({
    ...service,
    actions: renderActions(service),
  }));

  return (
    <Container maxWidth={false} sx={{ p: 3 }}>
      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h4" component="h1">
            Services
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setSelectedService(undefined);
              setFormOpen(true);
            }}
          >
            Add Service
          </Button>
        </Box>
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Box>
      
      <DataTable
        columns={columns}
        rows={enrichedServices}
        total={total}
        page={page}
        rowsPerPage={rowsPerPage}
        loading={loading}
        error={error}
        searchValue={searchQuery}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onSearchChange={handleSearchChange}
      />

      <ServiceForm
        open={formOpen}
        onClose={() => {
          setFormOpen(false);
          setSelectedService(undefined);
        }}
        onSubmit={handleFormSubmit}
        initialData={selectedService}
        loading={loading}
        mode={selectedService ? 'edit' : 'create'}
      />

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete Service</DialogTitle>
        <DialogContent>
          Are you sure you want to delete {serviceToDelete?.title}?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={handleDeleteService}
            color="error"
            disabled={loading}
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}; 